<template>
  <div class="dynamic_wrapper" v-clickoutside="handleClose">
    <Input
      v-model="searchKey"
      ref="search_input"
      icon="ios-search-strong"
      class="search-input"
      @on-focus="searchByKey"
      :placeholder="placeholder"
      @on-keydown="pointer($event)"
      @click.stop.native="searchByKey"
      @on-enter="addPointerElement"
    />
    <Dropdown trigger="click" :visible="visible" placement="bottom-start" class="search-dropdown">
      <DropdownMenu slot="list">
        <ul class="down_wrapper" v-show="dataList.length > 0">
          <div class="search_list">
            <div class="group_title">
              {{ $route.query.me ? "@ Mention" : $route.query.name || userInfo.company.name }}
            </div>
            <li
              v-for="(item, index) in dataList"
              class="row_item"
              :class="{ 'row-highlight': dataSelect === index }"
              @click.prevent.stop="selectItem(index, item)"
              :key="item.label"
            >
              <div class="firstLine">
                <div class="leftInfo">
                  <avatar
                    v-if="part !== 'ship'"
                    class="avatar"
                    :email="removeHtmlTags(item.email)"
                    :fullname="removeHtmlTags(item.name)"
                    :avatarURL="item.avatar"
                  ></avatar>
                  <span class="user_name" v-html="item.name"></span>
                </div>
                <div class="rightInfo">
                  <span class="job" v-if="part === 'ship'">{{ item.year_of_build }}</span>
                  <div class="job" v-else>
                    {{ item.company }}
                    <span v-show="item.company && item.department">,</span>
                    {{ item.department }}
                  </div>
                </div>
              </div>
              <div class="secondLine ship" v-if="part === 'ship'">
                <p class="email_address">IMO: <span v-html="item.lrimo"></span></p>
                <p class="email_address" v-if="item.ex_name">Ex Name: <span v-html="item.ex_name"></span></p>
                <p class="email_address" v-if="item.call_sign">Call Sign: <span v-html="item.call_sign"></span></p>
              </div>
              <div class="secondLine" v-else>
                <span class="email_address" v-html="item.email"></span>
              </div>
              <div class="thirdLine">
                <div v-for="(i, index) in item.tags" :key="index" class="tag">
                  <div class="tagItem" v-if="index < 2 && i" v-html="i"></div>
                </div>
                <div class="tagItem" v-if="item.tags.length > 2">
                  <Icon type="ios-more"></Icon>
                </div>
              </div>
            </li>
            <div class="searchInView" @click="search()">Show all results</div>
          </div>
        </ul>
        <!----------------Loading noData---------------->
        <ul class="down_wrapper" v-show="dataList.length == 0 && !loading">
          <li class="no_data">No matching data</li>
        </ul>
        <ul class="down_wrapper" v-show="loading && dataList.length == 0">
          <li class="no_data">Loading</li>
        </ul>
      </DropdownMenu>
    </Dropdown>
  </div>
</template>

<script>
import api from "../../../fetch/api.js";
import debounce from "lodash/debounce"; //引入lodash
import { mapGetters } from "vuex";
import clickoutside from "../../../utils/clickoutside";
import avatar from "@/pages/components/avatar.vue";
import util from "util";
import { hotkeyHandler } from "@/mixins";
import utils from "@/utils";

export default {
  directives: { clickoutside },
  props: {
    part: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "Search",
    },
  },
  data() {
    return {
      visible: false,
      searchKey: "",
      dataList: [],
      loading: false,
      noData: false,
      placeholderText: "Search",
      dataSelect: 0,
    };
  },
  created() {},
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mixins: [hotkeyHandler],
  methods: {
    selectItem(index, item) {
      this.dataSelect = index;
      let path = this.part === "ship" ? `shipDetail/${item.id}` : "/contacts";
      if (this.part === "ship") {
        path += item.source ? `?source=${item.source}` : "";
      }

      let query = { ...this.$route.query, search_id: item.id, search_key: this.searchKey, rand: +new Date() };
      item.public && (query.name = "MarineTraffic");
      item.company && (query.company = item.company);
      item.department && (query.department = item.department);
      item.level && (query.level = item.level);
      this.part === "ship" ? util.openWindow(path) : this.$router.push({ path: path, query: query });
      this.visible = false;
      this.searchKey = "";
    },
    search() {
      let path = this.part === "ship" ? "/ships/shipping" : "/contacts";
      let query = { ...this.$route.query, search_key: this.searchKey, rand: +new Date(), level: "all" };
      this.$router.push({ path: path, query: query });
      this.visible = false;
      this.searchKey = "";
    },
    pointer(e) {
      //down
      if (e.keyCode === 40) {
        this.pointerForward();
      }
      //up
      if (e.keyCode === 38) {
        this.pointerBackward();
      }
    },
    pointerForward() {
      let s = this.dataList.length;

      if (s > 0) {
        if (this.dataSelect < s - 1) {
          this.dataSelect++;
        } else {
          this.dataSelect = s - 1;
        }
      }
    },
    pointerBackward() {
      let s = this.dataList.length;

      if (s > 0) {
        if (this.dataSelect > 0) {
          this.dataSelect--;
        } else {
          this.dataSelect = 0;
        }
      }
    },
    addPointerElement() {
      if (this.dataList.length > 0) {
        this.selectItem(this.dataSelect, this.dataList[this.dataSelect]);
      }
    },
    //------------------------focus
    searchByKey() {
      if (this.searchKey !== "") {
        this.visible = true;
      }
    },
    handleClose() {
      this.visible = false;
    },
    async getShip() {
      let res;
      try {
        this.loading = true;
        let params = {
          key: this.searchKey,
          ship_group_id: this.$route.query.id,
          public: this.$route.query.name == "MarineTraffic",
          me: this.$route.query.me,
          per_page: 3,
        };
        res = await api.get_shipping(params);
        this.loading = false;
      } catch (ex) {
        this.loading = false;
        this.dataList = [];
      }

      if (!res) return;
      this.dataList =
        res.ships.length === 0
          ? []
          : res.ships
              .map((ship) => utils.sanitizeObject(ship))
              .map((item) => {
                let word = this.searchKey;
                let validation = new RegExp("(" + word + ")", "i");
                item.lrimo = item.lrimo.toString();

                let name = item.name;
                let replaceName = validation.exec(name);
                let lrimo = item.lrimo;
                let replaceLrimo = validation.exec(lrimo);
                let ex_name = item.ex_name;
                let replaceEx_name = validation.exec(ex_name);
                let call_sign = item.call_sign;
                let replaceCall_sign = validation.exec(call_sign);

                let strTags = item.tags.join(",");
                let replaceTags = validation.exec(strTags);

                if (replaceName && name) {
                  item.name = name.replace(
                    new RegExp("(" + word + ")", "ig"),
                    "<strong>" + replaceName[0] + "</strong>",
                  );
                }
                if (replaceLrimo && lrimo) {
                  item.lrimo = lrimo.replace(
                    new RegExp("(" + word + ")", "ig"),
                    "<strong>" + replaceLrimo[0] + "</strong>",
                  );
                }
                if (replaceEx_name && ex_name) {
                  item.ex_name = ex_name.replace(
                    new RegExp("(" + word + ")", "ig"),
                    "<strong>" + replaceEx_name[0] + "</strong>",
                  );
                }
                if (replaceCall_sign && call_sign) {
                  item.call_sign = call_sign.replace(
                    new RegExp("(" + word + ")", "ig"),
                    "<strong>" + replaceCall_sign[0] + "</strong>",
                  );
                }

                if (replaceTags && strTags) {
                  strTags = strTags.replace(
                    new RegExp("(" + word + ")", "ig"),
                    "<strong>" + replaceTags[0] + "</strong>",
                  );
                  item.tags = strTags.split(",");
                }
                return item;
              });
    },
    async getContact() {
      let res;
      try {
        this.loading = true;
        const params = {
          key: this.searchKey,
          address_group_id: this.$route.query.id,
          public: this.$route.query.name == "MarineTraffic",
          me: this.$route.query.me,
          per_page: 3,
        };
        res = await api.get_contact_list(params);
        this.loading = false;
      } catch (_) {
        this.dataList = [];
        this.loading = false;
      }

      if (!res) return;

      this.dataList =
        res.address_books.length == 0
          ? []
          : res.address_books
              .map((address) => utils.sanitizeObject(address))
              .map((item) => {
                let word = this.searchKey;
                let validation = new RegExp("(" + word + ")", "i");

                let name = item.name;
                let replaceName = validation.exec(name);
                let email = item.email;
                let replaceEmail = validation.exec(email);
                let strTags = item.tags.join(",");
                let replaceTags = validation.exec(strTags);

                if (replaceName) {
                  item.name = name.replace(
                    new RegExp("(" + word + ")", "ig"),
                    "<strong>" + replaceName[0] + "</strong>",
                  );
                }
                if (replaceEmail) {
                  item.email = email.replace(
                    new RegExp("(" + word + ")", "ig"),
                    "<strong>" + replaceEmail[0] + "</strong>",
                  );
                }
                if (replaceTags) {
                  strTags = strTags.replace(
                    new RegExp("(" + word + ")", "ig"),
                    "<strong>" + replaceTags[0] + "</strong>",
                  );
                  item.tags = strTags.split(",");
                }
                return item;
              });
    },
    removeHtmlTags(html) {
      return util.sanitizeHtml(html);
    },
    handleHotkey(e) {
      if (e.key == "Escape") {
        e.preventDefault();
        this.searchKey = "";
        this.$refs.search_input && this.$refs.search_input.blur();
      }
    },
  },
  watch: {
    searchKey: debounce(function (val) {
      if (!!val && val.length >= 3) {
        this.visible = true;
        this.part === "ship" && this.getShip();
        this.part === "contact" && this.getContact();
      } else {
        this.visible = false;
        this.dataList = [];
      }
      this.dataSelect = 0;
      this.$el.querySelector(".down_wrapper").scrollTop = 0;
    }, 400),
  },
  components: {
    avatar,
  },
};
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.dynamic_wrapper {
  height: 36px;
  .search-input {
    position: absolute;
  }
  width: 100%;
  .search-dropdown {
    width: 100%;
    position: relative;
    top: 23px;
  }
  .down_wrapper {
    max-height: 550px;
    overflow: auto;
    overflow: overlay;
    text-align: left;
    .search_list {
      .group_title {
        font-size: 14px;
        color: var(--blue-main);
        font-weight: bold;
        padding: 0 8px;
        line-height: 40px;
      }
      .row_item {
        cursor: pointer;
        color: var(--on-component-color);
        padding-bottom: 5px;
        background-color: var(--component-color);
        border-bottom: solid 1px var(--border-color);
        &:last-child {
          border-bottom: none;
        }
        &:hover,
        &.row-highlight {
          background-color: var(--hover-color);
          color: var(--text-color);
        }
        .firstLine {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px 0 8px;
          .leftInfo {
            display: flex;
            align-items: center;
            .avatar {
              margin-right: 8px;
            }
            .user_name {
              font-size: 14px;
              line-height: 28px;
              /*margin-left: 8px;*/
            }
          }
          .rightInfo {
            .job {
              font-size: 12px;
              line-height: 32px;
              text-align: right;
              color: #828d9d;
            }
          }
        }
        .secondLine {
          padding-left: 44px;
          margin: 5px 0 5px 0;
          .email_address {
            font-size: 12px;
          }
          &.ship {
            padding-left: 8px;
            .email_address {
              display: inline-block;
              max-width: 120px;
              margin-right: 8px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .thirdLine {
          padding-left: 44px;
          .tag {
            display: inline-block;
          }
          .tagItem {
            display: inline-block;
            border: 1px solid var(--border-color);
            padding: 0 8px;
            height: 24px;
            line-height: 22px;
            border-radius: 22px;
            text-align: center;
            cursor: pointer;
            margin-right: 11px;
            .ivu-icon-more {
              line-height: 22px;
              vertical-align: middle;
            }
          }
        }
      }
      .searchInView {
        width: 100%;
        color: var(--primary-color);
        font-size: 14px;
        line-height: 40px;
        padding: 0 8px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .no_data {
      width: 100%;
      font-size: 14px;
      line-height: 45px;
      text-align: center;
    }
    .loading {
      width: 100%;
      height: 45px;
      font-size: 14px;
      line-height: 45px;
      color: #bbbec4;
      background: #fff;
      text-align: center;
    }
  }
}
</style>
