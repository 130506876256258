<template>
  <div class="suggest">
    <!-- ---- title ----- -->
    <div class="suggest-title" v-if="noData">MarineTraffic Inbox</div>
    <div class="suggest-title" v-else>Are you trying to add…</div>

    <!-- ------- content ------- -->
    <div class="suggest-content empty" v-if="noData">
      <div class="icon">
        <img src="/static/img/icon/icon_group.svg" alt="" />
      </div>
      <span>No similar {{ category }} detected.</span>
    </div>

    <div class="suggest-content" v-else>
      <div class="suggest-company" v-if="user.company">
        <avatar class="avatar" :fullname="user.company" :avatarURL="user.company_logo" :width="35"></avatar>
        <span class="name">{{ user.company }}</span>
      </div>

      <div class="contact">
        <avatar class="avatar" :fullname="user.name" :avatarURL="user.avatar_url" :width="30"></avatar>
        <div class="contact-person">
          <div class="contact-name" v-html="sanitizeTextHTML(username)"></div>
          <div class="contact-title">{{ user.job_title || "-" }}</div>
        </div>
      </div>
      <div class="contact-info">{{ user.note }}</div>
      <template v-for="(item, n) in emails">
        <div class="contact-detail" v-if="n < 1" :key="`email-${n}`">
          <div class="label">{{ item.value_type | capitalize }} Email</div>
          <div class="info" v-html="sanitizeTextHTML(item.value)"></div>
        </div>
      </template>
      <template v-for="(item, n) in phone">
        <div class="contact-detail" v-if="n < 1" :key="`phone-${n}`">
          <div class="label">{{ item.value_type | capitalize }} Phone</div>
          <div class="info" v-html="sanitizeTextHTML(item.value)"></div>
        </div>
      </template>
    </div>
    <!-- ----- footer ----- -->
    <div class="suggest-footer">
      <div v-if="noData" class="no-data">
        <strong>Let’s make MarineTraffic Inbox better, together.</strong>
        <div>With each new contact you share, you help someone else find the contact they need.</div>
      </div>
      <Button v-else @click="emitUser">Populate form</Button>
    </div>
  </div>
</template>

<script>
import api from "../../../fetch/api.js";
import avatar from "@/pages/components/avatar";

export default {
  props: {
    from: {
      type: Object,
    },
    emailArr: {
      type: Array,
      default: () => [],
    },
    phoneArr: {
      type: Array,
      default: () => [],
    },
    category: {
      type: String,
    },
  },
  data() {
    return {
      noData: true,
      user: {
        address_book_id: 0,
        avatar_url: "",
        name: "",
        emails: [],
        phones: [],
        job_title: "",
        note: "",
      },
      //        ajaxList: [],
      currentTime: 0,
    };
  },
  filters: {
    capitalize(value) {
      if (!value) return "Work";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
  computed: {
    //      company () {
    //        const company = this.companies.find(ele => this.user.address_book_id === ele.id)
    //        return company ? company.name : ''
    //      },
    //      name
    username() {
      if (!this.user.name) return "-";
      const name = this.from.name;
      try {
        const validation = new RegExp(name, "ig");
        const newName = validation.exec(this.user.name);
        if (this.from.name && newName) {
          return this.user.name.replace(
            validation,
            "<span class='colored-bg' style='background: rgba(32, 53, 128, 0.16)'>" + newName[0] + "</span>",
          );
        }
      } catch (ex) {}

      return this.user.name;
    },
    emails() {
      let list = this.user.emails.map((e) => {
        if (typeof e === "object") {
          return Object.assign({}, e);
        } else {
          return e;
        }
      });
      return list.map((item) => {
        if (item.value == "" || !item.value) {
          item.value = "-";
          return item;
        }
        const word = this.emailArr[0].value;
        if (!word) return item;

        try {
          const validation = new RegExp(word, "ig");
          const value = item.value;
          const replaceEmail = validation.exec(value);
          if (item.value && replaceEmail) {
            item.value = value.replace(
              validation,
              "<span style='background: rgba(32, 53, 128, 0.16)'>" + replaceEmail[0] + "</span>",
            );
          }
        } catch (ex) {}

        return item;
      });
    },
    phone() {
      let list = this.user.phones.map((e) => {
        if (typeof e === "object") {
          return Object.assign({}, e);
        } else {
          return e;
        }
      });
      return list.map((item) => {
        if (item.value == "" || !item.value) {
          item.value = "-";
          return item;
        }
        let word = this.phoneArr[0].value;
        if (!word) return item;

        try {
          const validation = new RegExp(word, "ig");
          const value = item.value;
          const replaceValue = validation.exec(value);
          if (item.value && replaceValue) {
            item.value = value.replace(
              validation,
              "<span style='background: rgba(32, 53, 128, 0.16)'>" + replaceValue[0] + "</span>",
            );
          }
        } catch (ex) {}
        return item;
      });
    },
  },
  methods: {
    clearSuggest() {
      this.noData = true;
    },
    init(obj) {
      const params = {
        name: obj.name,
        email: obj.email,
        phone: obj.phone,
        level: obj.level,
      };
      api
        .get_contact_suggest(params)
        .then((res) => {
          if (res.address_book) {
            this.noData = false;
            this.user = res.address_book;
          } else {
            this.noData = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    emitUser() {
      this.$emit("fill", this.user);
    },
  },
  components: {
    avatar,
  },
};
</script>
