<template>
  <!-------------abort send------------>
  <Modal
    v-model="confirmShow"
    title="Cancel sending this email?"
    width="465"
    :styles="{ top: '238px' }"
    class="dialog"
    @on-ok="abortSend"
  >
    This email will be saved as a draft.
  </Modal>
</template>

<script>
import MessageEx from "components/Message-ex";
import api from "api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mailDetailActionMixin } from "@/mixins";
import util from "util";

export default {
  data() {
    return {
      confirmShow: false,
      loading: false,
    };
  },
  props: ["checkGroup"],
  computed: {
    ...mapGetters(["bookmark"]),
  },
  mixins: [mailDetailActionMixin],
  methods: {
    ...mapActions(["removeBookmark"]),
    ...mapMutations(["DELETE_MAIL_LIST_ITEMS"]),
    show() {
      this.$nextTick(() => {
        this.confirmShow = true;
      });
    },
    async abortSend() {
      this.loading = true;
      try {
        await this.requestAbortSend(this.checkGroup);
        this.confirmShow = false;
        this.loading = false;
        MessageEx.success({
          content: `Aborted send for ${this.checkGroup.length} emails.`,
          btnContent: "GO TO DRAFTS",
          duration: 5,
          onButtonClick: () => {
            this.$router.push("/mails?box=Draft");
          },
        });
        [this.bookmark, this.autoBookmark].forEach((bookmark) => {
          this.checkGroup.findIndex((id) => id == bookmark?.email_id) > -1 && this.removeBookmark(bookmark);
        });
      } catch (err) {
        console.log(err);
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  &-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cancelText {
      cursor: pointer;
      margin-right: 40px;
      font-size: 16px;
      line-height: 1.19;
    }
  }
}
</style>
