<template>
  <div class="rule-criteria">
    <section class="rule">
      <!------------ Description--------->
      <div class="fileRule">
        <div class="title">
          <span>{{ descText }}</span
          >&nbsp;
          <!--Mail scope-->
          <Dropdown trigger="click" class="dropDown-wrapper" :transfer="true" @on-click="onMailScopeChange">
            <div class="dropTarget">
              <span class="dropText large">{{ mail_scope.name }}</span>
              <span class="dropIcon">
                <Icon type="md-arrow-dropdown" size="12" color="#194d78"></Icon>
              </span>
            </div>
            <DropdownMenu slot="list" class="dropContain file">
              <DropdownItem
                v-for="(item, index) in incoming"
                :name="index"
                class="dropItem"
                v-show="mail_scope.name !== item.name"
                :key="item.name"
                :disabled="readOnly"
              >
                {{ item.name }}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          &nbsp;<span>meets</span>&nbsp;
          <!--Match types-->
          <Dropdown trigger="click" class="dropDown-wrapper" :transfer="true" @on-click="onMatchTypeChange">
            <div class="dropTarget">
              <span class="dropText large">{{ match_type.name }}</span>
              <span class="dropIcon">
                <Icon type="md-arrow-dropdown" size="12" color="#194d78"></Icon>
              </span>
            </div>
            <DropdownMenu slot="list" class="dropContain all">
              <DropdownItem
                v-for="(item, index) in all"
                :name="index"
                class="dropItem"
                v-show="match_type.name !== item.name"
                :key="item.name"
                :disabled="readOnly"
              >
                {{ item.name }}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          &nbsp;<span>condition(s):</span>
        </div>
        <p v-if="!readOnly" class="deleteAll" @click="handleRemoveAll">Delete all</p>
      </div>
      <!--------------Search in------->
      <Row v-if="criteria.search_in_enable" class="rule-account">
        <Col span="4" class="formName"> Search in </Col>
        <Col span="20">
          <div class="search-in">
            <span class="name">{{ searchInName }}</span>
            <Icon type="md-close" class="remove-icon" @click="onRemoveSeachIn" />
          </div>
        </Col>
      </Row>
      <!------------- Lines----------->
      <Row v-if="lineShow" class="rule-account">
        <Col span="4" class="formName"> Mailbox </Col>
        <Col span="20">
          <Select
            :value="criteria.line_ids"
            filterable
            multiple
            placeholder="Select"
            :transfer="true"
            :readonly="readOnly"
            @on-change="onAccountChange"
          >
            <Checkbox
              v-if="lines.length > 0"
              class="rule-clear-all"
              :value="criteria.line_ids.length === lines.length"
              @click.prevent.native="onLineSelectAll"
            >
              <span class="rule-clear-all-label">ALL</span>
            </Checkbox>
            <Option
              v-for="item in sortedLines"
              :value="item.id"
              :key="item.id"
              :label="item.sortName"
              class="line-account-item"
            >
              <Checkbox :value="!!criteria.line_ids.find((l) => l === item.id)"></Checkbox>
              <span class="highlight">{{ item.sortName }}</span>
              <span v-if="item.email">{{ `<${item.email}>` }}</span>
            </Option>
          </Select>
          <p
            v-if="
              criteria.line_ids.length === 0 &&
              ((formDynamic.items && formDynamic.items.length > 0) || folderType === 'dynamic')
            "
            class="warning-select-accounts"
          >
            Mailbox cannot be empty.
          </p>
        </Col>
      </Row>
      <!-------------- Dynamis form rules----------->
      <Form ref="formDynamic" :model="formDynamic" class="form-rule" v-if="formDynamic.items.length > 0">
        <Row v-if="boolItems && boolItems.length > 0" class="bool-items">
          <Col span="4">&nbsp;</Col>
          <Col span="19" class="items">
            <!-- eslint-disable-next-line -->
            <div v-for="(item, index) in boolItems" class="item">
              <Checkbox v-model="item.content" label="" class="checkbox" />
              <span class="formName">{{ item.name }}</span>
            </div>
          </Col>
        </Row>
        <template v-for="(item, index) in formDynamic.items">
          <FormItem
            v-if="item.show && boolItems.findIndex((i) => i.field === item.field) === -1"
            :key="index"
            :prop="'items.' + index + '.content'"
            :rules="{ validator: validate, trigger: 'blur' }"
          >
            <Row class="rule-item">
              <!--Form label-->
              <!--Label for Email address-->
              <Col span="4" class="formName" v-if="item.name == 'Email Address'">
                <Dropdown
                  trigger="click"
                  class="dropDown-wrapper"
                  :transfer="true"
                  placement="bottom-start"
                  @on-click="handleFromDrop($event, index)"
                >
                  <div class="dropTarget">
                    <span class="dropText">{{ item.field | capitalize }}</span>
                    <span class="dropIcon"><Icon type="md-arrow-dropdown" size="12" color="#194d78"></Icon></span>
                  </div>
                  <DropdownMenu slot="list" class="dropContain from">
                    <DropdownItem
                      v-for="i in from"
                      :name="i.value"
                      class="dropItem"
                      v-show="item.field !== i.value"
                      :key="i.label"
                    >
                      {{ i.label }}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Col>
              <!--Label for others-->
              <Col span="4" class="formName" v-else>
                {{ item.name }}
              </Col>
              <!--Strategy-->
              <Col span="5" v-if="item.field !== 'tags' && item.strategies && item.strategies.length > 1">
                <Select v-model="item.strategy" :transfer="true" :readonly="readOnly">
                  <Option v-for="k in item.strategies" :value="k.value" :key="k.value">
                    {{ k.label }}
                  </Option>
                </Select>
              </Col>
              <!--Form input-->
              <Col
                :span="
                  (item.field === 'tags' ? 19 : item.strategies && item.strategies.length > 1 ? 13 : 19) +
                  (readOnly ? 1 : 0)
                "
                :offset="item.field === 'tags' ? 0 : item.strategies && item.strategies.length > 1 ? 1 : 0"
              >
                <!--Multiple selection-->
                <template v-if="item.type == types.multiple_select">
                  <Select
                    v-model="item.content"
                    filterable
                    multiple
                    placeholder="Select"
                    :transfer="true"
                    :readonly="readOnly"
                  >
                    <Checkbox
                      v-if="item.type_values.length > 0"
                      class="rule-clear-all"
                      :value="item.content.length === item.type_values.length"
                      @click.prevent.native="onItemSelectAll(item)"
                    >
                      <span class="rule-clear-all-label">ALL</span>
                    </Checkbox>
                    <Option
                      v-for="e in item.type_values"
                      :value="e.value"
                      :key="e.value"
                      :label="e.label"
                      class="line-account-item"
                    >
                      <Checkbox :value="item.content.includes(e.value)" />
                      <span class="highlight">{{ e.option_label || e.label }}</span>
                    </Option>
                  </Select>
                </template>
                <!--Single selection-->
                <template v-else-if="item.type === types.single_select">
                  <Select
                    v-model="item.content"
                    :transfer="true"
                    :readonly="readOnly"
                    @on-change="onSingleSelectChanged(item)"
                  >
                    <Option
                      v-for="option in item.type_values"
                      :key="option.value"
                      :value="option.value"
                      :label="option.label"
                    >
                      {{ option.label }}
                    </Option>
                  </Select>
                </template>
                <!--Text-->
                <template v-else-if="item.type === types.text">
                  <div v-if="item.field === 'tags'">
                    <Dropdown
                      v-if="showTagEditor"
                      :visible="openSuggestionTag && tagSuggestions.length > 0"
                      trigger="custom"
                      class="tag-dropDown-wrapper"
                      :transfer="true"
                      placement="bottom-start"
                      @on-click="onSelectStrategy($event, index)"
                      @on-clickoutside="onSelectStrategy(item.strategy, index)"
                    >
                      <InputTag
                        :tags="item.content"
                        :readonly="readOnly"
                        :onChange="(value) => onUpdateSuggestionTag(value, item.strategies)"
                      ></InputTag>
                      <p v-if="item.content.length === 0" class="guide-strategy">
                        Insert text and hit enter or space to show suggestion
                      </p>

                      <DropdownMenu slot="list" class="dropContain from">
                        <DropdownItem v-for="i in tagSuggestions" :name="i.value" class="dropItem" :key="i.label">
                          {{ i.label }}
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <div v-else class="tags-content">
                      <p class="guide-strategy" v-html="getTagsWarning(item)"></p>
                      <Icon type="md-create" class="tags-action" @click="onEditTag(item)"></Icon>
                    </div>
                  </div>
                  <Input
                    v-else
                    type="text"
                    :maxlength="maxLengthInput(item)"
                    v-model="item.content"
                    placeholder=""
                    :readonly="readOnly"
                  />
                </template>
                <!--Number-->
                <template v-else-if="item.type === types.number">
                  <InputNumber v-model="item.content" :readonly="readOnly" />
                </template>
                <!--Custom: Assigned to-->
                <template v-else-if="item.type === types.custom_user_select">
                  <Select
                    v-model="item.content"
                    :transfer="true"
                    multiple
                    clearable
                    filterable
                    :readonly="readOnly"
                    @on-change="onCustomUserSelectChange($event)"
                  >
                    <Option value="any">Any</Option>
                    <Option value="none">None</Option>
                    <Option
                      v-for="member in teamMembers"
                      :key="member.id"
                      :value="member.id"
                      :label="member.name || member.user_name"
                    >
                      <Checkbox :value="item.content && item.content.includes(member.id)"></Checkbox>
                      {{ member.name || member.user_name }}
                      <span v-if="member.email">{{ `<${member.email}>` }}</span>
                    </Option>
                  </Select>
                </template>
              </Col>
              <!--Remove rule item-->
              <Col v-if="!readOnly" span="1">
                <Icon type="md-close" class="remove" @click="handleRemove(index)" />
              </Col>
            </Row>
            <!--Description-->
            <Row>
              <Col offset="4" class="rule-desc">{{ item.desc }}</Col>
            </Row>
            <!--Date range picker-->
            <Row v-if="item.field == 'received_at' && item.content === 'custom'" class="datePicker">
              <Col span="4">&nbsp;</Col>
              <Col span="20">
                <span class="rangeText">Between</span>
                <DatePicker
                  type="date"
                  :transfer="true"
                  class="ruleDatePicker"
                  placeholder="dd/mm/yyyy"
                  format="dd/MM/yyyy"
                  v-model="item.firstDate"
                  style="width: 200px"
                />
                <span class="rangeText">to</span>
                <DatePicker
                  type="date"
                  :transfer="true"
                  class="ruleDatePicker"
                  v-model="item.lastDate"
                  format="dd/MM/yyyy"
                  placeholder="dd/mm/yyyy"
                  style="width: 200px"
                />
              </Col>
            </Row>
          </FormItem>
        </template>
      </Form>
    </section>
    <!------------Add condition------------>
    <Row v-if="!readOnly" class="addRule">
      <Dropdown class="dropDown-wrapper" trigger="click" :transfer="true" placement="bottom-start">
        <div class="dropTarget">
          <span class="dropText">Add condition</span>
          <span class="dropIcon"><Icon type="md-arrow-dropdown"></Icon></span>
        </div>
        <DropdownMenu slot="list">
          <DropdownItem
            class="dropItem"
            v-for="(item, index) in definedRules"
            v-bind:key="index"
            v-show="['closed', 'assignment'].includes(item.value) ? item.show && p_enabled_assignment : item.show"
            @click.native="addRuleOption(item)"
            >{{ item.name }}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import utils from "@/utils";
import { allRules, normalRules, types, strategyTextMapping } from "@/common/constants/rules.constant";
import InputTag from "@/pages/components/InputTag.vue";

export default {
  components: {
    InputTag,
  },
  props: {
    descText: {
      required: true,
      type: String,
    },
    criteria: {
      type: Object,
      required: true,
      default: () => {
        return {
          search_in_enable: false,
          mail_scope: "both",
          match_type: "every",
          line_ids: [],
          items: [],
        };
      },
    },
    definedRules: {
      type: Array,
      required: false,
      default: normalRules,
    },
    readOnly: Boolean,
    allowMultipleLines: {
      type: Boolean,
      default: true,
    },
    initialRules: {
      type: Array,
      default: () => [],
    },
    folderType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      openSuggestionTag: false,
      showTagEditor: false,
      tagSuggestions: [],
      incoming: [
        { name: "incoming mail", value: "incoming" },
        { name: "outgoing mail", value: "outgoing" },
        { name: "incoming and outgoing mail", value: "both" },
      ],
      all: [
        { name: "all", value: "every" },
        { name: "at least one", value: "one" },
      ],
      from: [
        { value: "From", label: "From" },
        { value: "To", label: "To" },
        { value: "Bcc", label: "Bcc" },
        { value: "Cc", label: "Cc" },
      ],
      types: types,
      formDynamic: {
        items: [],
      },
      originalFormDynamic: undefined,
      originalCriteria: undefined,
    };
  },
  computed: {
    ...mapState(["lines_all"]),
    ...mapGetters(["teamMembers", "setting_company"]),
    lineShow() {
      if (this.folderType === "dynamic") return true;

      if (this.criteria.search_in_enable) return false;
      return (
        this.criteria.line_ids.length > 0 || this.formDynamic.items.length > 0 || this.initialRules.includes("lines")
      );
    },
    match_type() {
      return this.all.find((t) => t.value == this.criteria.match_type) || { name: "all", value: "every" };
    },
    mail_scope() {
      return (
        this.incoming.find((t) => t.value == this.criteria.mail_scope) || { name: "incoming mail", value: "incoming" }
      );
    },
    p_enabled_assignment() {
      return this.setting_company.assignment_module == "1";
    },
    lines() {
      if (!this.criteria.line_ids || this.criteria.line_ids.length == 0) return this.lines_all.lines;
      const notExistedId =
        this.criteria.line_ids.filter((id) => this.lines_all.lines.findIndex((line) => line.id == id) == -1) || [];
      const notExistedLines =
        this.lines_all.full_lines.filter((line) => notExistedId.findIndex((id) => line.id == id) > -1) || [];
      return this.lines_all.lines.concat(notExistedLines);
    },
    searchInName() {
      let result = this.$route_box_name();
      if (this.$route_line_id()) result = this.$route_line_name();
      if (this.$route_folder_name()) result = this.$route_folder_name();
      return result;
    },
    boolItems() {
      return this.formDynamic.items.filter((i) => i.type === this.types.bool);
    },
    sortedLines() {
      return this.lines
        .map((line) => {
          line.sortName = line.username || line.display_name;
          return line;
        })
        .sort((a, b) => (a.sortName.toLowerCase() < b.sortName.toLowerCase() ? -1 : 1));
    },
  },
  filters: {
    capitalize(val) {
      return utils.capitalizeFirstLetter(val);
    },
  },
  created() {
    (!this.lines_all || this.lines_all.lines.length == 0) && this.loadLines();
  },
  mounted() {
    this.initDefaultRules();
  },
  methods: {
    ...mapActions(["loadLines"]),
    onSelectStrategy(value, index) {
      this.formDynamic.items[index].strategy = value;
      this.openSuggestionTag = false;
      this.showTagEditor = false;
    },
    onUpdateSuggestionTag(value, strategies) {
      if (value.length === 0) {
        this.tagSuggestions = [];
        return;
      }
      const tmp = [];
      let i = 0;
      while (i < strategies.length) {
        let j = 0;
        let label = strategyTextMapping[strategies[i].value].prefix;
        while (j < value.length) {
          if (j > 1) {
            label += " ...";
            j = value.length;
          } else {
            label += ` ${value[j]} ${j !== value.length - 1 ? strategyTextMapping[strategies[i].value].middle : ""}`;
          }
          j++;
        }
        const idx = tmp.findIndex((el) => el.label === label);
        if (idx === -1) {
          tmp.push({
            value: strategies[i].value,
            label,
          });
        }
        i++;
      }
      this.tagSuggestions = tmp;
      setTimeout(() => {
        this.openSuggestionTag = true;
      });
    },
    onEditTag(item) {
      this.showTagEditor = true;
      this.onUpdateSuggestionTag(item.content, item.strategies);
    },
    initDefaultRules() {
      this.lineShow &&
        this.initialRules &&
        this.initialRules.includes("lines") &&
        (this.criteria.line_ids = this.lines_all.lines.map((l) => l.id));

      this.initialRules.forEach((field) => {
        let rule = this.definedRules.find((r) => r.field === field);
        rule && rule.field === "keyword" && (rule.content = this.$route.query.search_key);
        rule && this.addRuleOption(rule);
      });
    },
    bindRuleCriteriaForm(criteria) {
      let rules = criteria.items;

      this.formDynamic.items = [];
      if (!rules || rules.length == 0) {
        return this.formDynamic;
      }

      for (let rule of rules) {
        const ruleTemplate = this.definedRules.find(
          (r) => r.field == rule.field || (r.field == "from" && ["to", "from", "bcc", "cc"].includes(rule.field)),
        );
        rule = { ...ruleTemplate, ...rule };
        switch (rule.type) {
          case types.multiple_select:
            rule.content = rule.content.split(",").map((n) => n);
            rule.type_values = this.getTypeValues(rule.field);
            break;
          case types.number:
            rule.content = +rule.content;
            break;
          case types.bool:
            rule.content = rule.content === "has";
            break;
          case types.custom_user_select:
            if (rule.content_type == "range") {
              rule.content = rule.content.split(",").map((u) => +u);
            }
            break;
        }
        switch (rule.field) {
          case "received_at":
            if (rule.content_type == "range") {
              let content = rule.content.split(",");
              rule.firstDate = new Date(content[0]);
              rule.lastDate = new Date(content[1]);
              rule.content = "custom";
            }
            break;
          case "tags":
            rule.content = rule.content.split(" ");
            break;
        }
        this.formDynamic.items.push(rule);
      }
      return this.formDynamic;
    },
    async getRuleCriteria() {
      if (!this.$refs.formDynamic) {
        this.criteria.items = [];
        return this.criteria;
      }

      let formRules = this.formDynamic.items.filter((item) => !!item.show);
      formRules = formRules.map((e) => {
        return typeof e === "object" ? Object.assign({}, e) : e;
      });

      let valid = await this.$refs.formDynamic.validate();
      if (!valid || (!this.criteria.search_in_enable && this.criteria.line_ids.length === 0 && formRules.length > 0)) {
        this.$Message.error("Please check your form");
        return false;
      }

      for (let item of formRules) {
        if (item.field == "received_at" && item.content == "custom") {
          if (item.firstDate == "" || item.lastDate == "") {
            this.$Message.error("Please fill out the effective time range");
            return false;
          }

          if (item.firstDate.getTime() > item.lastDate.getTime()) {
            this.$Message.error("Please fill out the effective time range");
            return false;
          }

          item.content = item.firstDate + "," + item.lastDate;
          item.content_type = "range";

          delete item.firstDate;
          delete item.lastDate;
        }

        if (item.type === this.types.custom_user_select) {
          item.content = String(item.content);
          item.content_type = item.content.includes("any") || item.content.includes("none") ? "single" : "range";
        }

        item.type === types.multiple_select && (item.content = String(item.content));
        item.type === types.bool && (item.content = !!item.content ? "has" : "");

        item.field = item.field.toLowerCase();

        if (item.type === this.types.text && item.field === "tags") {
          item.content = item.content.join(" ");
        }

        delete item.name;
        delete item.type;
        delete item.show;
        delete item.type_values;
        delete item.strategies;
        delete item.multipleShow;
        delete item.desc;
      }
      this.criteria.items = formRules.slice(0);
      return this.criteria;
    },

    onMailScopeChange(index) {
      this.criteria.mail_scope = this.incoming[index].value;
    },
    onMatchTypeChange(index) {
      this.criteria.match_type = this.all[index].value;
    },
    onCustomUserSelectChange(selectedItems) {
      if (!this.formDynamic || !this.formDynamic.items || this.formDynamic.items.length == 0) return;

      let rule = this.formDynamic.items.find((i) => i.type == this.types.custom_user_select);
      if (!rule || !rule.content || rule.content.length == 0) return;

      const lastSelected = rule.content[rule.content.length - 1];
      if (lastSelected == "any" || lastSelected == "none") {
        rule.content.splice(0, rule.content.length - 1);
        return;
      }

      rule.content = rule.content.filter((u) => u != "any" && u != "none");
    },
    onSingleSelectChanged(item) {
      item.field == "has_attachment" && this.updateAttachmentRules(item);
    },
    addRuleOption(option) {
      let type_values = this.getTypeValues(option.field);
      let rule = {
        ...option,
        strategy: option.strategies && option.strategies.length > 0 ? option.strategies[0].value : "equal",
        content: option.content
          ? option.field === "tags"
            ? option.content.split(" ")
            : option.content
          : option.type == types.number
          ? 0
          : option.type == types.text && option.field === "tags"
          ? []
          : "",
        case_sensitive: false,
        type_values: type_values || option.type_values,
      };
      this.formDynamic.items.push(rule);
      if (option.field === "tags") {
        this.showTagEditor = true;
      }
      this.$emit("onAddRule", rule);
    },
    onAccountChange(items) {
      this.criteria.line_ids = this.allowMultipleLines ? items : [items[items.length - 1]];
    },
    handleFromDrop(val, index) {
      this.formDynamic.items[index].field = val;
    },
    handleRemove(index) {
      if (this.formDynamic.items[index].field === "tags") {
        this.showTagEditor = false;
        this.openSuggestionTag = false;
      }
      this.$emit("onRemoveRule", this.formDynamic.items[index]);
      this.formDynamic.items.splice(index, 1);
    },
    handleRemoveAll() {
      this.criteria.line_ids = [];
      this.formDynamic.items = [];
      this.showTagEditor = false;
      this.openSuggestionTag = false;
      this.$emit("onRemoveAll");
    },
    getTypeValues(field) {
      const rule = this.definedRules.find((r) => r.field == field);
      if (rule.type_values && rule.type_values.length > 0) return rule.type_values;

      let typeValues;
      switch (field) {
        case "user_id":
          typeValues =
            this.teamMembers &&
            this.teamMembers.map((m) => {
              return {
                label: m.user_name,
                value: String(m.id),
                option_label: m.user_name + ` <${m.email && m.email}>`,
              };
            });
          break;
      }
      return typeValues;
    },
    validate(rule, e, callback) {
      const arr = rule.field.split(".");
      const index = arr[1];
      if (this.formDynamic.items[index].field === "subject" || this.formDynamic.items[index].field === "body") {
        const value = this.formDynamic.items[index].content;
        if (value.indexOf("@") !== -1) {
          callback(new Error("Please do not fill out @"));
        }
      }
      callback();
    },
    updateAttachmentRules(item) {
      if (!item) return;
      ["attachment_name", "attachments_count"].forEach((field) => {
        let rule = this.formDynamic.items.find((i) => i.field === field);
        rule && (rule.show = item.content != "none");
        let dropAddItem = this.definedRules.find((i) => i.field === field);
        dropAddItem && !rule && (dropAddItem.show = item.content != "none");
      });
    },
    checkHasChange() {
      const formDynamicChange = !utils.compare2Object(this.originalFormDynamic, this.formDynamic);
      const criteriaChange = !utils.compare2Object(this.originalCriteria, this.criteria);
      return formDynamicChange || criteriaChange;
    },
    clearAccounts() {
      this.criteria.line_ids = [];
    },
    maxLengthInput(item) {
      return ["subject", "body", "subject_or_body"].includes(item.field) ? 200 : null;
    },
    onLineSelectAll() {
      const isSelectAll = this.criteria.line_ids.length === this.lines.length;
      isSelectAll
        ? this.criteria.line_ids.splice(0, this.criteria.line_ids.length)
        : (this.criteria.line_ids = this.lines.map((l) => l.id));
    },
    onItemSelectAll(item) {
      if (!item) return;
      const isSelectAll = item.content.length === item.type_values.length;
      isSelectAll ? item.content.splice(0, item.content.length) : (item.content = item.type_values.map((i) => i.value));
    },
    onRemoveSeachIn() {
      this.criteria.search_in_enable = false;
      this.criteria.line_ids = this.lines_all.lines.map((l) => l.id);
    },
    getTagsWarning(item) {
      if (item.content.length === 0) {
        return "Empty";
      }
      let result = strategyTextMapping[item.strategy].prefix;
      let i = 0;
      while (i < item.content.length) {
        result += ` <strong>${item.content[i]}</strong> ${
          i !== item.content.length - 1 ? strategyTextMapping[item.strategy].middle : ""
        }`;
        i++;
      }
      return this.sanitizeTextHTML(result);
    },
  },
  watch: {
    criteria: {
      immediate: true,
      handler: function (val) {
        this.originalCriteria = JSON.parse(JSON.stringify(val));
        const dynamicForm = this.bindRuleCriteriaForm(val);
        this.originalFormDynamic = JSON.parse(JSON.stringify(dynamicForm));
      },
    },
    "formDynamic.items": {
      immediate: true,
      handler: function (items) {
        const fields = allRules.map((rule) => rule.field);
        fields.forEach((field) => {
          let ruleExisted = !!items.find((i) => i.field === field);
          let dropAddItem = this.definedRules.find((i) => i.field === field);
          dropAddItem && !dropAddItem.multipleShow && (dropAddItem.show = !ruleExisted);
        });
        const has_attachment = this.formDynamic.items.find((item) => item.field == "has_attachment");
        has_attachment && this.updateAttachmentRules(has_attachment);

        items.forEach((item) => {
          if (["subject_or_body"].includes(item.field)) {
            ["subject", "body"].forEach((itm) => {
              const d = this.definedRules.find((i) => i.field === itm);
              d && (d.show = false);
            });
            return;
          }

          if (["subject", "body"].includes(item.field)) {
            const subjectOrBodyDrop = this.definedRules.find((i) => i.field === "subject_or_body");
            subjectOrBodyDrop && (subjectOrBodyDrop.show = false);
            return;
          }
        });
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.warning-select-accounts {
  margin-top: 4px;
  color: var(--error-color);
  font-size: 12px;
}
.tag-dropDown-wrapper {
  width: 100%;
}
.tags-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tags-action {
  cursor: pointer;
}
.guide-strategy {
  top: 33px;
  left: 5px;
  margin-top: 4px;
  color: var(--additional-info-color);
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ::v-deep strong {
    background-color: var(--background-color);
    padding: 3px 6px;
    border-radius: 6px;
  }
}
.dropDown-wrapper {
  .dropTarget {
    cursor: pointer;
    .dropText {
      font-size: 14px;
      color: var(--primary-color);
      margin-right: 5px;

      &.large {
        font-size: 14px;
        margin-right: 0;
      }
    }
    .dropIcon {
      color: var(--primary-color);
    }
  }
}
.rule {
  padding: 8px 0;

  .formName {
    font-size: 14px;
    line-height: 24px;
  }

  .rule-account {
    margin-top: 16px;
    display: flex;
    align-items: center;
  }

  .search-in {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid var(--border-color);
    border-radius: 25px;
    padding-left: 16px;
    .name {
      color: var(--primary-color);
      margin-right: 8px;
      font-weight: bold;
    }
    .remove-icon {
      font-size: 24px;
      color: var(--text-color);
      padding: 6px 8px;
      opacity: 0.7;
      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  .fileRule {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      display: flex;
      font-size: 14px;
      font-weight: normal;
      white-space: nowrap;
    }

    p {
      cursor: pointer;
      font-size: 14px;

      &:hover {
        opacity: 0.7;
      }
    }

    .deleteAll {
      color: var(--label-color);
      &:hover {
        color: var(--primary-color);
      }
    }
  }
  .form-rule {
    .ivu-form-item {
      margin-top: 16px;
      margin-bottom: 0;
    }
    .rule-item {
      display: flex;
      align-items: center;
    }
    .bool-items {
      padding: 8px 0;
      margin-top: 16px;
      .items {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .item {
        display: flex;
        align-items: center;
      }
      .checkbox {
        margin-right: 16px;
      }
    }
  }
  .case {
    display: block;
    width: 50px;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    background-color: #ffffff;
    border: solid 1px #d8e2e7;
    border-left: none;
  }
  .remove {
    font-size: 24px;
    display: block;
    padding: 8px;
    height: 40px;
    cursor: pointer;
    color: var(--text-color);
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
  .rule-desc {
    font-size: 12px;
    line-height: 20px;
    color: var(--label-color);
  }
  .datePicker {
    margin-top: 16px;
    .rangeText {
      font-size: 14px;
      line-height: 1.71;
      color: #828d9d;
      margin: 0 6px;
      &:first-child {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }
}
.addRule {
  margin-top: 16px;
}
</style>
<style lang="scss">
.rule-criteria {
  .ivu-form-item-error-tip {
    left: unset;
    right: 30px;
    padding-top: 2px;
  }
}
.rule-clear-all {
  padding: 16px 16px 8px 16px;
  &-label {
    padding-left: 4px;
    color: var(--label-color);
  }
}
</style>
