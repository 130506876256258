<template>
  <div class="detail-wrapper">
    <!--loading-->
    <div class="loading" v-show="loading">
      <Spin size="large" fix></Spin>
    </div>
    <div class="detail-contain">
      <section class="leftContain">
        <!--main-->
        <div class="detail-top-btns">
          <slot name="hide-btn" />
          <div class="expandIcon" @click="isExpanded = !isExpanded">
            <Tooltip :content="isExpanded ? 'Collapse' : 'Expand'" class="actionable-icon">
              <svg-sprite :class="{ rotate180: isExpanded }" name="mt_open_side_pane" :width="15" :height="15" />
            </Tooltip>
          </div>
        </div>
        <div class="main-contain" v-if="!threadMailId">
          <!--company-->
          <div class="company" v-if="detailData.company">
            <avatar
              class="avatar"
              :width="30"
              :email="detailData.email"
              :fullname="detailData.company"
              :avatarURL="detailData.company_logo"
            />
            <h2 class="companyTitle">
              {{ detailData.company }}
            </h2>
          </div>
          <hr v-if="detailData.company" />
          <!--detail info -->
          <div class="detailInfo">
            <!--avatar-->
            <div class="leftSide">
              <div class="contact-info">
                <avatar
                  class="avatar"
                  :width="30"
                  :email="detailData.email"
                  :fullname="detailData.name"
                  :avatarURL="detailData.avatar_url"
                />
                <div class="info">
                  <div class="name">
                    {{ detailData.name }}
                    <i v-if="detailData.chartdesk_user_id" class="icon ch-icon-cdverified big"></i>
                  </div>
                  <div class="job-title" v-if="detailData.job_title">
                    {{ detailData.job_title }} {{ detailData.department ? `in ${detailData.department}` : "" }}
                  </div>
                  <div class="job-title" v-else></div>
                </div>
              </div>
              <slot name="company-info-count" />
            </div>
            <!--edit-->
            <div class="rightSide">
              <Dropdown placement="bottom-end">
                <button>
                  <icon-svg icon-class="triangle_down" class="triangle" :style="{ width: '5px', height: '5px' }">
                  </icon-svg>
                </button>
                <Dropdown-menu slot="list">
                  <Dropdown-item @click.native="copyLink" class="copyPublic"> Copy public link </Dropdown-item>
                  <Dropdown-item @click.native="copyContact" class="copyContact"> Copy contact </Dropdown-item>
                  <Dropdown-item v-if="p_edit_contact" @click.native="editContact"> Edit </Dropdown-item>
                  <Dropdown-item v-if="p_delete_contact" @click.native="confirmShow = true">Delete</Dropdown-item>
                </Dropdown-menu>
              </Dropdown>
            </div>
          </div>
          <div class="describe" :title="detailData.note" v-if="detailData.note">
            <p>{{ detailData.note }}</p>
          </div>
          <!--row-->
          <div class="row-contain">
            <!--user id-->
            <!-- <Row class="row">
              <Col :span="rowData" class="row-data">
              {{detailData.chartdesk_user_id ? detailData.chartdesk_user_id : '-'}}</Col>
            </Row> -->
            <!--Salutation-->
            <Row class="row">
              <Col :span="rowName" class="row-name">Salutation</Col>
              <Col :span="rowData" class="row-data">
                {{ detailData.salutation && detailData.salutation.length > 0 ? detailData.salutation[0].value : "-" }}
              </Col>
            </Row>
            <!--First name-->
            <Row class="row" v-if="isContactLevel">
              <Col :span="rowName" class="row-name">First name</Col>
              <Col :span="rowData" class="row-data"> {{ detailData.first_name || "-" }}</Col>
            </Row>
            <!--Last name-->
            <Row class="row" v-if="isContactLevel">
              <Col :span="rowName" class="row-name">Last name</Col>
              <Col :span="rowData" class="row-data"> {{ detailData.last_name || "-" }}</Col>
            </Row>
            <!--email phone ims-->
            <Row class="row" v-for="(i, n) in detailData.emails" :key="`${i.value_type}${i.value}`">
              <Col :span="rowName" class="row-name">
                {{ i.value_type | capitalize }} Email
                <br />
                <small v-if="i.default">(Default)</small>
              </Col>
              <Col :span="rowData" class="row-data">
                <div
                  class="contact-data-clickable"
                  :title="i.value ? `Email to ${convert2Text(i.value)}` : ''"
                  @click.prevent.stop="handleClickEmailAddress(i)"
                  v-html="i.value || '-'"
                ></div>
              </Col>
            </Row>
            <Row class="row" v-for="(i, n) in detailData.phones" :key="`${i.value_type}${i.value}`">
              <Col :span="rowName" class="row-name"> {{ i.value_type | capitalize }} Phone </Col>
              <Col :span="rowData" class="row-data">
                <div :title="i.value ? `Call ${i.value}` : ''" class="contact">
                  <a class="contact-data-clickable" v-if="i.value" :href="`tel:${convert2Text(i.value)}`">{{
                    i.value
                  }}</a>
                  <span v-else>-</span>
                </div>
              </Col>
            </Row>
            <!-- eslint-disable-next-line -->
            <Row
              class="row"
              v-for="(i, n) in detailData.ims"
              v-if="detailData.level === 'contact'"
              :key="`${i.value_type}${i.value}`"
            >
              <Col :span="rowName" class="row-name"> {{ i.value_type | capitalize }} </Col>
              <Col :span="rowData" class="row-data">
                <div class="contact">{{ i.value || "-" }}</div>
              </Col>
            </Row>
            <!--Street Address-->
            <Row class="row">
              <Col :span="rowName" class="row-name"> Street Address</Col>
              <Col :span="rowData" class="row-data"> {{ detailData.postal_address || "-" }}</Col>
            </Row>
            <!--City-->
            <Row class="row">
              <Col :span="rowName" class="row-name"> City</Col>
              <Col :span="rowData" class="row-data"> {{ detailData.city || "-" }}</Col>
            </Row>
            <!--State/Province-->
            <Row class="row">
              <Col :span="rowName" class="row-name"> State/Province</Col>
              <Col :span="rowData" class="row-data"> {{ detailData.state || "-" }}</Col>
            </Row>
            <!--Postal Code-->
            <Row class="row">
              <Col :span="rowName" class="row-name"> Postal Code</Col>
              <Col :span="rowData" class="row-data"> {{ detailData.postal_code || "-" }}</Col>
            </Row>
            <!--Country-->
            <Row class="row">
              <Col :span="rowName" class="row-name"> Country</Col>
              <Col :span="rowData" class="row-data"> {{ detailData.country_name || "-" }}</Col>
            </Row>
            <!--Web/URL-->
            <Row class="row">
              <Col :span="rowName" class="row-name"> Web/URL</Col>
              <Col :span="rowData" class="row-data">
                <span
                  class="contact-data-clickable"
                  @click.prevent="openExternalUrl(detailData.website)"
                  v-if="detailData.website"
                >
                  {{ detailData.website }}
                </span>
                <span v-else>-</span>
              </Col>
            </Row>
            <!--tags-->
            <Row class="row">
              <Col :span="rowName" class="row-name"> Tags</Col>
              <Col :span="rowData" class="row-data">
                <div v-if="filteredTags.length > 0">
                  <Tag
                    v-for="tag in filteredTags"
                    :closable="p_edit_contact"
                    :key="tag"
                    class="group-item"
                    @on-close="() => handleRemoveTag(tag)"
                  >
                    {{ tag }}
                  </Tag>
                </div>
                <div v-else>-</div>
              </Col>
            </Row>
            <contact-group :canEdit="p_edit_contact" :contactId="detailData.id" />
          </div>
          <!--See all Arrowship contacts(button)-->
          <div class="ctaButton see-button">
            <button v-if="detailData.level != 'contact'" @click="seeContacts">
              <div class="text-ellipsis">See all {{ detailData.name }} contacts</div>
            </button>
          </div>
        </div>
        <div class="main-contain" v-else>
          <ch-mail
            ref="emailContent"
            :userAvatar="null"
            :isDetail="true"
            :mailId="threadMailId"
            :assignment_ids="mailDetail.assignment_ids"
            @update-email-content="(content) => (mailDetail = content)"
          />
        </div>
      </section>
      <!----------------------tabs-------------------------->
      <section class="rightContain tab-align-center" v-if="isExpanded">
        <email-tab-panel @tab-selected="handleTabSelected">
          <template v-slot:comments>
            <ch-chat
              :commentable_id="detailData.id"
              :comments="comments"
              :height="'calc(100vh - 132px)'"
              :comment_type="'AddressBook'"
              :tags="tags"
              :uniqueId="detailData.name"
              :placeholder="'Mention @yourteammate or #tag this contact'"
              ref="chChat"
            >
            </ch-chat>
          </template>
          <template v-slot:details>
            <div class="details-contain">
              <!--actions-->
              <div class="tab-details-item">
                <div class="details-header">
                  <i class="iconfont-actions"></i>
                  <span>Actions</span>
                </div>
                <div v-if="actions.length > 0" class="details-content">
                  <ul class="actions">
                    <li
                      v-for="(item, index) in actions"
                      :key="index"
                      class="action-item"
                      v-html="sanitizeTextHTML(item.content)"
                    ></li>
                  </ul>
                </div>
                <div v-else class="noData">
                  <p>No data found</p>
                </div>
              </div>
              <!--Tags-->
              <div class="tab-details-item">
                <div class="details-header">
                  <i class="iconfont-tag"></i>
                  <span>Tags</span>
                </div>
                <div v-if="tags.length > 0" class="details-content">
                  <div v-for="tag in tags" :key="tag" class="tagItem">
                    {{ tag }}
                    <i class="iconfont ch-icon-cross_1" @click="deleteTag(tag)"></i>
                  </div>
                </div>
                <div v-else class="noData">
                  <p>No data found</p>
                </div>
              </div>
              <!--Group-->
              <div class="tab-details-item">
                <div class="details-header">
                  <i class="iconfont-contacts"></i>
                  <span>Groups</span>
                </div>
                <div v-if="groups.length > 0" class="details-content">
                  <ul class="folders">
                    <li v-for="(item, index) in groups" :key="index" @click="clickGroup(item)">
                      <div>
                        <h1>{{ item.name }}</h1>
                        <p>Filed by {{ item.user_name }} at {{ item.created_at | format_date_time }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-else class="noData">
                  <p>No data found</p>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:threads>
            <mail-threads :target_id="detailData.id" @updateThreadMailId="(id) => (threadMailId = id)" />
          </template>
        </email-tab-panel>
      </section>
    </div>
    <Modal
      v-model="confirmShow"
      title="Delete"
      width="380"
      :styles="{ top: '238px' }"
      class="dialog"
      @on-ok="deleteContact"
    >
      Do you want to delete this contact? It will be removed from Shared/Personal Contact Groups.
    </Modal>
    <Cfeedback type="AddressBook" :id="detailData.id" ref="feedback"></Cfeedback>
  </div>
</template>

<script type="text/ecmascript-6">
import api from "api";
import util from "util";
import chat from "../../../components/comments/Cchat.vue";
import Clipboard from "clipboard";
import Cfeedback from "../../../components/Cfeedback.vue";
import { mapGetters, mapActions } from "vuex";
import avatar from "@/pages/components/avatar.vue";
import ContactGroup from "../../aside/ContactGroup.vue";
import EmailTabPanel from "@/components/EmailTabPanel.vue";
import MailThreads from "@/components/MailThreads.vue";
const chMail = () => import("@/pages/components/Cmailitem.vue");
import LocalStorageConstant from "@/common/constants/local-storage.constant";
import utils from '@/utils'

export default {
  data() {
    return {
      rowName: 8,
      rowData: 16,
      confirmShow: false,
      deleteLoading: false,
      detailData: {
        tags: []
      },
      loading: false,
      isExpanded: true,
      currentTag: "comments",
      actions: [],
      tags: [],
      groups: [],
      comments: [],
      currentTab: "comments",
      threadMailId: null,
      mailDetail: {},
      companyInfo: {}
    };
  },
  props: {
    selectedContactId: {
      type: Number
    }
  },
  inject: {
    closeAside: { default: () => {} }
  },
  computed: {
    ...mapGetters(["userInfo", "setting_mails"]),
    p_edit_contact() {
      if (this.detailData.public) {
        if (this.userInfo.user.manage_chartdesk_contact) {
          return true;
        } else {
          return false;
        }
      }
      return this.checkPermission("add_and_edit_contacts_in_company_directory");
    },
    isContactLevel() {
      if(!this.detailData || !this.detailData?.level) return
      return this.detailData?.level === 'contact'
    },
    p_delete_contact() {
      if (this.detailData.public) {
        if (this.userInfo.user.manage_chartdesk_contact) {
          return true;
        } else {
          return false;
        }
      }
      return this.checkPermission("delete_contacts_in_company_directory");
    },
    filteredTags() {
      return (this.tags || []).filter(tag => !!tag)
    }
  },
  created() {
    this.handleSocket();
  },
  methods: {
    ...mapActions(["loadThreadEmails"]),
    handleRemoveTag(tag) {
      this.$Modal.confirm({
        title: 'Confirmation',
        content: `<p style="margin-left:-42px">Are you sure to remove <b>${tag}</b>?</p>`,
        onOk: async () => {
          this.deleteTag(tag)
        }
      });
    },
    handleTabSelected(tab) {
      this.threadMailId = null;
      this.currentTab = tab;
    },
    async tabChange() {
      if (this.currentTab === "threads") {
        return this.loadThreadEmails({ target_id: this.detailData.id, page: 1 });
      }
      this.getDetail()
    },
    editContact() {
      this.closeAside && this.closeAside();

      if (this.detailData.level === "contact") {
        this.$router.push(`/contacts/editContact/${this.detailData.id}`);
      } else if (this.detailData.level === "company") {
        this.$router.push(`/contacts/editCompany/${this.detailData.id}`);
      } else {
        this.$router.push(`/contacts/editDepartment/${this.detailData.id}`);
      }
    },
    async copyLink() {
      try {
        const res = await api.get_share_id({
          shareable_type: "AddressBook",
          shareable_id: this.selectedContactId || this.$route.params.id
        })

        if (!res) throw "Failed to get share contact";

        const tenantID = util.getTenantID();
        let url = `${window.location.origin}/#/shares?uuid=${res.share.uuid}`;
        url = tenantID ? url + `&baseUrl=${btoa(api.baseURL())}` : url;

        util.copyToClipboard(url)
        this.$Message.success("Copy public link successfully");
      } catch (error) {
        this.$Message.error("Copy public link failed, please try again later");
      }

    },
    copyContact() {
      const clipboard = new Clipboard(".copyContact", {
        text: () => {
          const data = this.detailData;

          return `Name: ${data.name}\nEmail Address: ${data.email}\nPhone: ${
            data.phone
          }`;
        }
      });

      clipboard.on("success", e => {
        this.$Message.success("Copy contact success!");
        e.clearSelection();
        clipboard.destroy();
      });

      clipboard.on("error", e => {
        this.$Message.error("Copy contact failed, please try again later!");
        e.clearSelection();
        clipboard.destroy();
      });
    },
    getDetail() {
      this.loading = true;
      const params = {
        id: this.selectedContactId || this.$route.params.id,
        via: this.$route.query.via
      };
      api
        .get_contact_detail(params)
        .then(res => {
          this.detailData = utils.sanitizeObject(res.address_book);
          this.comments = res.comments;
          this.actions = res.actions;
          this.groups = res.groups;
          this.tags = res.address_book.tags;
          document.title = `${res.address_book.name}`;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    async getCompanyInfo() {
      const res = await api.get_company_info({ address_book_id: this.selectedContactId || this.$route.params.id });
      this.companyInfo = res && res.company || {};
    },
    deleteTag(name) {
      this.tags = this.tags.filter(t => t !== name)

      const params = {
        tagable_type: "AddressBook",
        tagable_id: this.selectedContactId || this.$route.params.id,
        names: [name]
      };
      api
        .delete_tag(params)
        .then(res => {
          this.$Message.success("Remove tag successfully");
        })
        .catch(error => {
          console.log(error);
        });
    },
    reportError() {
      this.$refs.feedback.show();
    },
    deleteContact() {
      this.deleteLoading = true;
      api
        .delete_contact(this.detailData.id)
        .then(res => {
          this.deleteLoading = false;
          this.confirmShow = false;
          this.$Message.success("Delete contact successfully!");
          setTimeout(() => {
            this.$emit("refresh");
            /contactDetail/i.test(window.location.href) && window.close();
          }, 1000);
        })
        .catch(err => {
          console.log(err);
          this.deleteLoading = false;
        });
    },
    seeContacts() {
      if (this.$route.params.id || (this.selectedContactId && this.$route.name !== 'Contacts')) {
        const query = {
          affiliation_id: this.companyInfo.company_address_id,
          level: this.detailData.level === "department" ? "contact" : undefined,
          department_id: this.detailData.level === "department" ? this.detailData.id : undefined,
          company_name: this.companyInfo.company_name,
          department_name: this.detailData.level==="department" ? this.detailData.name : undefined
        }
        localStorage.setItem(LocalStorageConstant.SEE_CONTACTS_QUERY, JSON.stringify(query))
        this.$router.push({name: "Contacts"})
        return;
      }
      this.$emit("seeAllContacts");
    },
    clickGroup(i) {
      window.opener.window.location.href =
        "/#/contacts?name=" + encodeURIComponent(i.name) + "&id=" + i.id;
    },

    handleSocket() {
      let socket = this.$ActionCable.subscriptions.subscriptions[0];
      let self = this;
      socket.received.comment = data => {
        let number = 0;
        console.log({data})

        if (data.message.length > 0) {
          data.message.forEach(item => {
            if (
              item.commentable_id === parseInt(this.selectedContactId || this.$route.params.id) &&
              item.commentable_type === "AddressBook" &&
              item.user_id !== this.userInfo.user.id
            ) {
              number += 1;
              this.comments.push(item);
            }
          });
        }

        if (number > 0) {
          this.$nextTick(() => {
            this.$refs.chChat && this.$refs.chChat.scrollToBottom();
          });
        }
      };
      socket.received.tag = function(data) {
        if (data.type === "Tag") {
          if (data.message[0].tagable_type === "AddressBook") {
            self.updateTags(data.message[0]);
          }
        }
      };
    },
    updateTags(tag) {
      if(!tag || this.detailData.id != tag.tagable_id)
        return;
      const index = this.tags.findIndex(name => name == tag.name);
      !tag.deleted_at && index == -1 && this.tags.push(tag.name);
      tag.deleted_at && index > -1 && this.tags.splice(index, 1);
    },
    convert2Text(html) {
      return util.sanitizeHtml(html)
    },
    handleClickEmailAddress(item) {
      const email = this.convert2Text(item.value)
      const path = `sendMail?mailto=mailto%3A${encodeURIComponent(email)}`
      util.openWindow(path)
    },
  },
  filters: {
    formatData(GMT) {
      let timeString = "";
      timeString = util.relativeDay(GMT) + ", " + util.time_12(GMT);
      return timeString;
    },
    capitalize(value) {
      if (!value) return "Work";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  watch: {
    currentTab: 'tabChange',
    selectedContactId: {
      immediate: true,
      handler: function() {
        this.getDetail()
        this.getCompanyInfo()
      }
    }
  },
  components: {
    "ch-chat": chat,
    Cfeedback,
    avatar,
    ContactGroup,
    EmailTabPanel,
    MailThreads,
    "ch-mail": chMail
  }
};
</script>

<style lang="scss" scoped rel="stylesheet/scss">
/*结构*/
.detail-wrapper {
  text-align: left;
  height: 100%;
  .detail-contain {
    display: flex;
    height: 100%;
    border: 1px solid var(--border-color);
    background: var(--surface-color);
    padding-bottom: 5px;
    .leftContain {
      flex: 1;
      min-width: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      .detail-top-btns {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 16px;
        text-align: left;
        border-bottom: 1px solid var(--border-color);
        height: 40px;

        .btn-groups {
          border-radius: 6px;
          margin-right: 8px;
          i {
            font-size: 20px;
            vertical-align: middle;
          }
        }
        .expandIcon {
          cursor: pointer;
          border: none;
          .iconfont {
            line-height: 32px;
            font-size: 14px;
          }
        }
      }
      .main-contain {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0;
        .company {
          display: flex;
          align-items: center;
          padding: 4px 16px;
          h2 {
            display: inline-block;
            font-size: 16px;
            vertical-align: middle;
            font-weight: bold;
            margin-left: 10px;
          }
        }
        .detailInfo {
          display: flex;
          justify-content: space-between;
          padding: 13px 16px;
          border-bottom: 1px solid var(--border-color);
          .leftSide {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            .contact-info {
              display: flex;
              align-items: flex-start;
            }
            .info {
              margin-left: 20px;
              .name {
                text-align: left;
                margin-bottom: 8px;
                font-size: 16px;
                font-weight: bold;
              }
              .job-title {
                word-wrap: break-word;
                text-align: left;
                font-size: 14px;
                line-height: 1.29;
                margin-bottom: 8px;
              }
            }
          }
          .rightSide {
            align-self: center;
            button {
              display: flex;
              align-items: center;
              justify-content: space-around;
              border: none;
              color: #fff;
              width: 100%;
              height: 30px;
              width: 30px;
              background: var(--blue-main);
              cursor: pointer;
              svg {
                fill: #fff;
              }
            }
          }
        }
        .describe {
          padding: 16px;
          p {
            font-size: 14px;
            line-height: 1.8;
            color: var(--on-component-color);
            margin-right: 31px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left;
          }
        }
        .row-contain {
          flex: 1;
          min-height: 0;
          overflow-y: auto;
          overflow-y: overlay;
          padding: 16px;
          padding-top: 0;
          .row {
            margin-bottom: 24px;
          }
          .row-name {
            font-size: 12px;
            color: #828d9d;
            line-height: 1.9;
          }
          .row-data {
            font-size: 14px;
            word-wrap: break-word;
            vertical-align: top;
            .contact-data-clickable {
              cursor: pointer;
              color: var(--blue-main);
              font-weight: bold;
            }
            &:hover .clickable {
              text-decoration: underline;
            }
          }
          .tagItem {
            display: inline-block;
            border: 1px solid var(--border-color);
            border-radius: 20px;
            padding: 4px 8px;
            text-align: center;
            margin-right: 11px;
            margin-bottom: 5px;
          }
        }
        .see-button {
          align-self: center;
          max-width: 500px;
          margin-bottom: 16px;
          .name-initials {
            display: inline-block;
            vertical-align: bottom;
            max-width: 150px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding: 0 4px;
          }
        }
        hr {
          border: none;
          border-bottom: 1px solid var(--border-color);
        }
      }
    }
    .rightContain {
      width: 300px;
      min-width: 300px;
      border-left: solid 1px var(--border-color);
      .details-contain {
        height: calc(100vh - 53px);
        overflow: auto;
        overflow: overlay;
        padding: 30px 16px 0 16px;
      }
    }
  }
}

.department {
  .ch-preview-title {
    margin: 0 16px 8px 16px;
    padding-top: 48px;
  }
}
</style>
