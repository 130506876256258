<template>
  <resizable-block class="aside-wrapper" ref="previewDiv" :resizeWidth="true" type="shipWidth" :defaultWidth="700">
    <aside class="aside-container">
      <div v-show="loading" class="ship-loading"></div>
      <div class="aside-header">
        <div class="left-side">
          <div class="actionable-icon">
            <i class="iconfont ch-icon-cross_1" @click="$emit('hide')"></i>
          </div>
          <h2 v-html="sanitizeTextHTML(asideData.name)" />
        </div>
        <div class="right-side">
          <Tooltip content="In new window" class="actionable-icon">
            <div class="btn" @click="openNewWindow(`shipDetail/${asideData.id}?source=${asideData.source}`)">
              <i class="iconfont-in-new-window"></i>
            </div>
          </Tooltip>
          <Tooltip content="Expand" class="actionable-icon">
            <i v-if="!isExpanded" class="iconfont ch-icon-expand_left" @click="isExpanded = true"></i>
            <i v-else class="iconfont ch-icon-expand_right" @click="isExpanded = false"></i>
          </Tooltip>
        </div>
      </div>
      <div class="main-contain">
        <ShipDetailItem
          :specData="specData"
          :isExpanded="isExpanded"
          @shipDeleted="$emit('hide')"
          :source="asideData.source"
        />
      </div>
    </aside>
  </resizable-block>
</template>

<script>
import util from "util";
import api from "api";
import ResizableBlock from "@/components/ResizableBlock.vue";
import ShipDetailItem from "pages/ships/detail/shipDetailItem.vue";

export default {
  props: {
    asideData: Object,
  },
  data() {
    return {
      specData: {
        overview: {
          public: true,
        },
      },
      loading: false,
      isExpanded: false,
    };
  },
  mounted() {
    document.addEventListener("keyup", this.handleEsc);
  },
  destroyed() {
    document.removeEventListener("keyup", this.handleEsc);
  },
  methods: {
    getData() {
      this.loading = true;
      const params = {
        id: this.asideData.id,
        source: this.asideData.source,
      };
      util.getDataFromSw("ship-detail", (data) => {
        this.specData = { ...data, id: +params.id };
      });
      api
        .ship_detail_change(params)
        .then((res) => {
          this.loading = false;
          this.specData = { ...res, id: +params.id };
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    handleEsc(event) {
      if (event.isComposing || event.keyCode === 229) {
        return;
      }
      if (event.key === "Escape") {
        this.$emit("hide");
      }
    },
    openNewWindow(suburl) {
      if (this.$route.query.via) {
        suburl = suburl + "?via=notification";
      }
      util.openWindow(suburl);
    },
  },
  watch: {
    asideData: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
  },
  components: {
    ShipDetailItem,
    ResizableBlock,
  },
};
</script>
<style lang="scss" scoped rel="stylesheet/scss">
.aside-wrapper {
  position: fixed;
  height: 100%;
  min-width: 600px;
  max-width: 80%;
  overflow: hidden;
  top: 0;
  right: 0;
  z-index: 1000;
  box-sizing: border-box;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  background-color: var(--component-color);
  z-index: 1000;

  .aside-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--border-color);

    .aside-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 16px;
      z-index: 4;
      .left-side {
        display: flex;
        align-items: center;
        h2 {
          font-size: 16px;
          font-weight: 700;
          color: var(--primary-color);
        }
        .actionable-icon {
          margin-right: 16px;
        }
      }
      .right-side {
        display: flex;
        align-items: center;
        .actionable-icon + .actionable-icon {
          margin-left: 8px;
        }
      }
    }
    .main-contain {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      background-color: var(--surface-color);
    }
  }
}
</style>
