<template>
  <div class="container-wrapper addContact">
    <!--loading-->
    <div class="loading" v-show="addLoading">
      <Spin size="large" fix></Spin>
    </div>
    <section class="container">
      <!--title-->
      <header class="headerTitle">
        <div class="leftSide">
          <h2 @click="$router.go(-1)">
            <i class="iconfont ch-icon-arrow_right backIcon"></i>
            {{ pageName }}
          </h2>
        </div>
        <div class="rightSide">
          <Button type="primary" @click="handleSubmit('formValidate')">Save</Button>
        </div>
      </header>
      <!--contain-->
      <div class="contain-wrapper">
        <!--formData-->
        <div class="form-contain">
          <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-position="left" :label-width="135">
            <!--avatar-->
            <Form-item label="Profile Photo" prop="avatar">
              <div class="imgUpload">
                <avatar class="avatar" :fullname="formValidate.name" :avatarURL="imgPreview" :width="60"></avatar>
                <div class="handleUpload">
                  <Upload
                    action="//jsonplaceholder.typicode.com/posts/"
                    :format="['jpg', 'gif', 'png']"
                    :max-size="2048"
                    :before-upload="handleBeforeUpload"
                  >
                    <Button>Change photo</Button>
                  </Upload>
                  <span>.jpg .gif or .png. Max file size 2MB</span>
                </div>
              </div>
            </Form-item>

            <!--Name-->
            <Form-item label="Name" prop="name">
              <Input v-model.trim="formValidate.name" />
            </Form-item>

            <!--Salutation-->
            <Form-item v-for="(item, index) in formValidate.salutation" :key="`salutation-${index}`" label="Salutation">
              <Input v-model.trim="item.value" />
            </Form-item>

            <!--email-->
            <Form-item
              v-for="(item, index) in formValidate.emails"
              v-bind:key="index"
              label="Email"
              class="contact-composite"
              :class="{ 'email-label': item.default }"
              :prop="'emails.' + index + '.value'"
              :rules="ruleValidate.email"
            >
              <div class="default-email-checkbox">
                <Checkbox
                  :label="item.value"
                  :value="item.default"
                  @on-change="(checked) => handleDefaultEmailChange(checked, item)"
                  >&nbsp;</Checkbox
                >
              </div>
              <Row class="rowItem" :key="`email-${index}`">
                <Col span="22">
                  <!--<Input v-model.trim="item.value" ></Input>-->
                  <Input v-model.trim="item.value" @on-change="searchContact(index)">
                    <Select v-model="item.value_type" slot="append" style="width: 90px">
                      <Option value="work">Work</Option>
                      <Option value="personal">Personal</Option>
                      <!-- <Option value="dept">Dept</Option> -->
                      <Option value="others">Others</Option>
                    </Select>
                  </Input>
                </Col>
                <Col span="2" class="formHandle">
                  <Icon
                    type="ios-add-circle-outline"
                    @click.native="handleAdd(index, 'email')"
                    :class="{ disable: formValidate.emails.length > 19 }"
                    v-if="index == 0"
                    size="20"
                    class="handleIcon"
                  ></Icon>
                  <Icon
                    type="ios-remove-circle-outline"
                    @click.native="handleRemove(index, formValidate.emails)"
                    v-else
                    size="20"
                    class="handleIcon"
                  ></Icon>
                </Col>
              </Row>
            </Form-item>
            <!--phone-->
            <Form-item label="Tel" prop="phone" class="contact-composite">
              <Row
                v-for="(item, index) in phoneDynamic.items"
                class="rowItem"
                :key="`phone-${index}`"
                :class="{ spacing: index > 0 }"
              >
                <Col span="22">
                  <Input v-model.trim="item.value" @on-change="searchContact(index)">
                    <Select v-model="item.value_type" slot="append" style="width: 90px">
                      <Option value="mobile">Mobile</Option>
                      <Option value="office">Office</Option>
                      <Option value="fax">Fax</Option>
                      <Option value="others">Others</Option>
                    </Select>
                  </Input>
                </Col>
                <Col span="2" class="formHandle">
                  <Icon
                    type="ios-add-circle-outline"
                    @click.native="handleAdd(index, 'phone')"
                    :class="{ disable: phoneDynamic.items.length > 3 }"
                    v-if="index == 0"
                    size="20"
                    class="handleIcon"
                  ></Icon>
                  <Icon
                    type="ios-remove-circle-outline"
                    @click.native="handleRemove(index, phoneDynamic.items)"
                    v-else
                    size="20"
                    class="handleIcon"
                  ></Icon>
                </Col>
              </Row>
            </Form-item>
            <!--company-->
            <Form-item label="Company" prop="company_name" class="ivu-form-item-required">
              <autoInput
                :dataList="companies"
                :category="'company'"
                ref="companyInput"
                placeholder="Select"
                :required="true"
                :disable="!!contactId"
                @selectedCompany="updateCompanyName"
                @searchCompanyDone="searchCompanyDone"
              />
            </Form-item>
            <!--Street Address-->
            <Form-item label="Street Address" prop="postal_address">
              <Input v-model.trim="formValidate.postal_address"></Input>
            </Form-item>
            <!--City-->
            <Form-item label="City" prop="city">
              <Input v-model.trim="formValidate.city"></Input>
            </Form-item>
            <!--state-->
            <Form-item label="State" prop="state">
              <Input v-model.trim="formValidate.state"></Input>
            </Form-item>
            <!--Postal code-->
            <Form-item label="Postal Code" prop="postal_code">
              <Input v-model.trim="formValidate.postal_code"></Input>
            </Form-item>
            <!--Country-->
            <Form-item label="Country" prop="country">
              <autoSelect
                :dataList="basicData.countries"
                :label="'1'"
                :pass="'0'"
                v-model="formValidate.country_id"
                placeholder="Select"
              >
              </autoSelect>
            </Form-item>
            <!--Web/URL-->
            <Form-item label="Web/URL" prop="website">
              <Input v-model.trim="formValidate.website"></Input>
            </Form-item>
            <!--note-->
            <Form-item label="Notes" prop="note">
              <Input type="textarea" style="resize: vertical" v-model.trim="formValidate.note" :maxlength="3000" />
            </Form-item>
            <!--tags-->
            <Form-item label="Tags" prop="tags">
              <input-tag
                :tags="tags"
                v-model="tags"
                :canAddNew="!definedTagsEnabled || p_tags"
                :getSuggestList="getTags"
                validate="tag"
              />
            </Form-item>
            <add-to-group ref="addToGroup" :contactName="formValidate.name" />
          </Form>
        </div>
        <!-- contact suggestion -->
        <div class="suggest-container">
          <suggest
            ref="suggest"
            v-if="contactSuggestionShow"
            @fill="getUser"
            :category="'departments'"
            :from="formValidate"
            :emailArr="formValidate.emails"
            :phoneArr="phoneDynamic.items"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script type="text/ecmascript-6">
  import api from '../../../fetch/api.js';
  import {mapActions, mapState, mapGetters} from 'vuex';
  import suggest from './suggest';
  import InputTag from '../../components/InputTag.vue';
  import autoInput from './autoInput.vue';
  import autoSelect from '../../components/autoSelect.vue';
  import { serialize } from 'object-to-formdata';
  import util from '../../../utils'
  import avatar from '@/pages/components/avatar.vue';
  import AddToGroup from './AddToGroup.vue'
  import { of } from 'rxjs';
  import {addContactMixin} from '@/mixins/index'

  export default {
    data() {
      const validateEmail = (rule, value, callback) => {
        const isValid = !value || util.filter.validators.email.test(value);
        !isValid && callback(new Error('Please enter valid email address.'))
        isValid && callback();
      }
      return {
        addLoading: false,
        pageName: 'Add new department',
        imgPreview: '',
        formValidate: {
          name: '',
          level: 'department',
          avatar: {},
          company_name: '',
          postal_address: '',
          city: '',
          state: '',
          postal_code: '',
          country_id: 0,
          website: '',
          note: '',
          emails: [{'item_type': 'email', 'value': '', 'value_type': 'work', 'default': true}],
          salutation: [{'item_type': 'salutation', 'value': '', 'value_type': '', 'default': true}],
        },
        ruleValidate: {
          name: [{required: true, message: 'Name could not be empty.'}],
          email: { validator: validateEmail, trigger: 'blur' }
        },
        phoneDynamic: {
          items: [
            {'item_type': 'phone', 'value': '', 'value_type': 'mobile', 'default': true},
          ]
        },
        companies: [],
        departments: [],
        country: '',
        tags: [],
      }
    },
    mixins: [addContactMixin],
    created () {
      !this.contactId && this.createInit();
      this.contactId && this.editInit();
    },
    computed: {
      ...mapState([
        'user',
        'basicData',
        'configuredTags'
      ]),
      ...mapGetters(["contactPreUpdate", "setting_company", "userInfo"]),
      isAdmin() {
        return this.userInfo.user.user_type === "admin";
      },
      p_tags() {
        return this.isAdmin || this.checkPermission("add_edit_delete_configured_tags");
      },
      definedTagsEnabled() {
        return this.setting_company.limit_hashtag == '1';
      },
      contactSuggestionShow() {
        return !this.contactId && this.setting_company.allow_chartdesk_contacts_directory == '1'
      },
      contactId() {
        return this.$route.name === 'editDepartment' ? this.$route.params.id : undefined
      }
    },
    methods: {
      handleDefaultEmailChange(checked, item) {
        this.formValidate.emails.forEach(e => e.default = false)
        item.default =  checked;
      },
      getDefaultEmails() {
        const email = !this.contactId ? this.$route.query.email : ''
        return [{'item_type': 'email', 'value': email || '', 'value_type': 'work', 'default': true}];
      },
      getDefaultSalutation() {
        return [{'item_type': 'salutation', 'value': '', 'value_type': '', 'default': true}];
      },
      searchCompanyDone(companies) {
        if(!companies)
          return;

        const isEdit = !!this.contactId;
        const searchKeyBinded = this.$route.query.company_name == this.$refs.companyInput.searchKey;
        const matchResult = companies[0] && this.$refs.companyInput.searchKey == companies[0].name;
        !isEdit && searchKeyBinded && matchResult && this.updateCompanyName(companies[0]);
      },
      updateCompanyName(item) {
        this.formValidate.company_name = item ? item.name : '';
        if(!item)
          return;
        this.populateAddress(item);
      },
       populateAddress(address) {
        this.formValidate.postal_address = address.postal_address
        this.formValidate.city = address.city
        this.formValidate.state = address.state
        this.formValidate.postal_code = address.postal_code
        this.formValidate.country_id = address.country_id
        this.formValidate.website = address.website
      },
      handleBeforeUpload(file){
        const imgSize = 1024000 * 2; // max img size
        if (file.size > imgSize) {
          this.$Message.warning('The file is too big, no more than 2M');
          return
        }
        if (!file.type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i)) {
          this.$Message.warning('Format is not correct, please upload a JPG or PNG format images.');
          return
        }
        this.formValidate.avatar = file;
        this.readFile(file, this.displayImg);
        return Promise
      },
      displayImg(file){
        let that = this;
        let reader = new FileReader();
        reader.onload = function (e) {
          that.imgPreview = e.target.result;
        };
        reader.readAsDataURL(file.file);
      },
      readFile(file, callback){
        let tempfile = {
          file: file,
          size: file.size,
          name: file.name,
        };
        callback(tempfile);
      },
      generateParams() {
        this.addLoading = true;
        this.formValidate.company_name = util.removeSearchHtml(this.formValidate.company_name);
        this.formValidate.name = util.removeSearchHtml(this.formValidate.name);
        this.formValidate.level = 'department';
        let address_book = Object.assign({}, this.formValidate);
        address_book.shared_groups = this.$refs.addToGroup.selectedSharedGroups.map(g => g.id)
        address_book.my_groups = this.$refs.addToGroup.selectedMyGroups.map(g => g.id)
        delete address_book.emails;
        delete address_book.salutation;
        let contact_items = [...this.formValidate.emails, ...this.phoneDynamic.items, ...this.formValidate.salutation];
        contact_items.map(item => {
          delete item.contact_item_id;
          if(item?.item_type === "email") item.value = this.sanitizeEmail(item?.value || "");
          return item;
        })
        return serialize({
          'address_book': address_book,
          'contact_items': JSON.stringify(contact_items),
          'tags': this.tags,
        });
      },
//---------------------提交表单
      handleSubmit (name) {
        const companyValid = this.$refs.companyInput.validate();
        if(!companyValid)
          return this.$Message.warning("Please check your form");

        this.$refs[name].validate((valid) => {
          if (valid) {
            const params = this.generateParams();
            this.contactId && api
              .update_contact(this.contactId, params)
              .then((res) => {
                this.$Message.success('Edited contact successfully.');
                this.$router.push(this.contactPreUpdate.currentPath || '/contacts')
              }).catch((error) => {
                console.log(error);
                this.addLoading = false;
              });

            !this.contactId && api
              .create_address_book(params)
              .then((res) => {
                if(this.initialData) {
                  this.$emit('done', res.address_book.id)
                  return
                }
                this.$Message.success('Added contact successfully.');
                this.contactPreUpdate.currentPath
                  ? this.$router.push(this.contactPreUpdate.currentPath)
                  : this.$router.push('/contacts?tab=contact')
              })
              .catch((error) => {
                console.log(error);
                this.addLoading = false;
            });
          } else {
            this.$Message.warning("Please check your form");
          }
        })
      },
//---------------------获取初始数据（edit）
      editInit() {
        this.addLoading = true;
        api.edit_contact(this.contactId).then((res) => {
          this.$refs.companyInput.searchKey = res.address_book.company || '';

          if (res.address_book.phones.length > 0) this.phoneDynamic.items = res.address_book.phones;
          this.tags = res.address_book.tags;
          this.imgPreview = res.address_book.avatar_url;

          const company_name = res.address_book.company;
          delete res.address_book.company;
          delete res.address_book.company_logo;
          delete res.address_book.department;
          delete res.address_book.is_edited;
          delete res.address_book.is_new;
          delete res.address_book.id;
          delete res.address_book.phones;
          delete res.address_book.ims;
          delete res.address_book.tags;
          delete res.address_book.avatar_url;

          this.formValidate = Object.assign({}, res.address_book);
          this.pageName = `${this.formValidate.level == 'non_contact' ? 'Add New' : 'Edit'} Department`;
          this.formValidate.company_name = company_name;
          (!this.formValidate.emails || this.formValidate.emails.length == 0) && (this.formValidate.emails = this.getDefaultEmails());
          (!this.formValidate.salutation || this.formValidate.salutation.length == 0) && (this.formValidate.salutation = this.getDefaultSalutation());
          this.addLoading = false;
        }).catch((error) => {
          console.log(error);
          this.addLoading = false;
        });
      },

      createInit() {
        Object.assign(this.$data, this.$options.data())
        this.formValidate.emails = this.getDefaultEmails();
        this.$refs.companyInput && (this.$refs.companyInput.searchKey = '');
      },

      searchContact (index) {
        if(!this.contactSuggestionShow)
          return

        if (this.contactId || index != 0)
          return;

        if (!this.formValidate.name && !this.formValidate.emails.value && !this.phoneDynamic.items[0].value) {
          this.$refs.suggest.clearSuggest()
        }

        if (this.formValidate.name.length < 3 && this.formValidate.emails[0].value.length < 3 && this.phoneDynamic.items[0].value.length < 3)
          return

        const params = {
          'name': this.formValidate.name,
          'email': this.formValidate.emails[0].value,
          'phone': this.phoneDynamic.items[0].value,
          'level': 'department',
        };
        this.$refs.suggest.init(params)
      },

      getUser (obj) {
        this.formValidate = {
          name: obj.name,
          level: obj.level,
          avatar: {},
          postal_address: obj.postal_address,
          city: obj.city,
          state: obj.state,
          postal_code: obj.postal_code,
          country_id: obj.country_id,
          website: obj.website,
          note: obj.note,
          company_name: obj.company,
          emails: this.getDefaultEmails(),
          salutation: this.getDefaultSalutation()
        };

        this.$refs.companyInput.searchKey = obj.company || '';
//        country
//        this.$refs.countrySelect.selectId = obj.country_id || '';
//        email/phone/im/tags
        if (obj.emails.length > 0) this.formValidate.emails = obj.emails.slice(0);
        if (obj.phones.length > 0) this.phoneDynamic.items = obj.phones.slice(0);
        if (obj.salutation.length > 0) this.formValidate.salutation = obj.salutation.slice(0);

        this.tags = obj.tags.slice(0);
        setTimeout(() => {
          this.$refs.formValidate.validate()
        }, 100)
      },
//-------------------------动态表单（email/phone）
      //  添加
      handleAdd(index, type){
        if (type === 'email') {
          if (this.formValidate.emails.length === 20) {
            return
          }
          let email = {'item_type': 'email', 'value': '', 'value_type': 'work', 'default': false};
          this.formValidate.emails.push(email)
        }
        if (type === 'phone') {
          if (this.phoneDynamic.items.length === 4) {
            return
          }
          let phone = {'item_type': 'phone', 'value': '', 'value_type': 'mobile', 'default': false};
          this.phoneDynamic.items.push(phone)
        }
      },
      handleRemove (index, arr) {
        arr.splice(index, 1);
      },
      getTags(name) {
        if(this.setting_company.limit_hashtag == '1') {
          const filteredList = this.configuredTags.filter(t => !!t.includes(name));
          return of(filteredList);
        }
        return api.suggest_tags({
          tagable_type: "AddressBook",
          key: name
        });
      }
    },
    watch: {
      '$route' () {
        this.contactId && this.editInit();
        !this.contactId && this.createInit();
      },
      'formValidate.name'(){
        this.searchContact(0);
      }
    },
    components: {
      InputTag,
      suggest,
      autoInput,
      autoSelect,
      avatar,
      AddToGroup
    }
  }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
.addContact {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .contain-wrapper {
      display: flex;
      flex: 1;
      overflow: auto;
      overflow: overlay;
      margin-bottom: 20px;
      .form-contain {
        flex: 6;
        padding: 24px;
        padding-bottom: 100px;
        .imgUpload {
          display: flex;
          .avatar {
            display: inline-block;
            margin-right: 24px;
          }
        }
        .handleUpload {
          display: inline-block;
          vertical-align: top;
          height: 60px;
          span {
            font-size: 12px;
            color: #828d9d;
          }
        }
        .contact-composite {
          .default-email-checkbox {
            position: absolute;
            top: 0px;
            left: -35px;
          }
          .rowItem {
            display: flex;
            align-items: center;

            &.spacing {
              margin-top: 10px;
            }
            .formHandle {
              .handleIcon {
                cursor: pointer;
                float: right;
                color: var(--primary-color);
                &.disable {
                  cursor: not-allowed;
                  color: var(--text-color-disable);
                }
              }
            }
          }
        }
      }
      .suggest-container {
        flex: 5;
      }
    }
  }
}
</style>
