import api from "api";
import util from "util";
import {
  SET_ROOT_FOLDER,
  ADD_FOLDER_TO_ROOT,
  SET_CHILD_FOLDERS,
  PREPEND_CHILD_FOLDERS,
  LOADING_CHILD_FOLDERS,
  SET_SELECTED_FOLDER,
  UPDATE_FOLDER_INFO,
  SET_STAR_UNREAD,
  SET_SHARE_UNREAD,
  SET_PERSONAL_UNREAD,
  UPDATE_FOLDER_CHILDREN,
  ADD_OPEN_FOLDER,
  REMOVE_OPEN_FOLDER,
  SET_DRAGGING_FOLDER,
} from "../../mutation-type";
import appConstant from "@/common/constants/app.constant";
import FolderHelper from "@/helpers/folder.helper";
import LocalStorageConstant from "@/common/constants/local-storage.constant";

const rootnames = ["stars", "shares", "personals"];

const pendingCopyFolders = {};

const state = {
  personals: [],
  shares: [],
  stars: [],
  childFolders: {},
  currentParent: 0,
  loadingChildFolders: false,
  selectedFolder: {},
  expandingFolders: localStorage.getItem(LocalStorageConstant.EXPANDING_FOLDERS)
    ? JSON.parse(localStorage.getItem(LocalStorageConstant.EXPANDING_FOLDERS))
    : {
        star: [],
        personal: [],
        share: [],
      },
  draggingFolderData: {},
};

const getters = {
  folders: (state) => state,
  expandingFolders: (state) => state.expandingFolders,
  childFolders: (state) => state.childFolders,
  draggingFolderData: (state) => state.draggingFolderData,
};
const mutations = {
  [SET_ROOT_FOLDER]: (state, params) => {
    params.stars && (state.stars = params.stars);
    params.shares && (state.shares = params.shares);
    params.personals && (state.personals = params.personals);
  },
  [ADD_FOLDER_TO_ROOT]: (state, { category, folders }) => {
    if (!state[`${category}s`]) return;

    state[`${category}s`] = [...folders, ...state[`${category}s`]];
  },
  [LOADING_CHILD_FOLDERS]: (state, param) => {
    state.loadingChildFolders = param;
  },
  [SET_CHILD_FOLDERS]: (state, { parentId, folders, category }) => {
    const children = Object.assign({}, state.childFolders, folders);

    for (const key in pendingCopyFolders) {
      const childFolders = children[key] || [];
      const newChildFolders = pendingCopyFolders[key] || [];

      children[key] = [...newChildFolders, ...childFolders];
    }

    state.childFolders = children;
    const rootFolders =
      category == appConstant.folderCategory.PERSONAL
        ? state.personals
        : category == appConstant.folderCategory.SHARE
        ? state.shares
        : state.stars;
    FolderHelper.updateFolderChildren(parentId, state.childFolders[parentId], rootFolders);
  },
  [PREPEND_CHILD_FOLDERS]: (state, { parentId, folders, category }) => {
    Object.assign(pendingCopyFolders, folders);
  },

  [SET_SELECTED_FOLDER]: (state, folder) => {
    state.selectedFolder = Object.assign({}, folder);
  },

  [SET_STAR_UNREAD]: (state, params) => {
    state.stars = params;
  },
  [SET_SHARE_UNREAD]: (state, params) => {
    state.shares = params;
  },
  [SET_PERSONAL_UNREAD]: (state, params) => {
    state.personals = params;
  },
  [UPDATE_FOLDER_INFO]: (state, { id, folder }) => {
    rootnames.forEach((name) => {
      let existedFolders = FolderHelper.findFolders(id, state[name]);
      existedFolders &&
        existedFolders.forEach((f) => {
          Object.assign(f, folder);
        });
    });
  },
  [UPDATE_FOLDER_CHILDREN]: (state, { folderId, category, folders }) => {
    const rootFolders =
      category == appConstant.folderCategory.PERSONAL
        ? state.personals
        : category == appConstant.folderCategory.SHARE
        ? state.shares
        : state.stars;
    FolderHelper.updateFolderChildren(folderId, folders, rootFolders);
  },
  [ADD_OPEN_FOLDER]: (state, { id, category }) => {
    const index = state.expandingFolders[category].findIndex((i) => i == id);
    if (index == -1) {
      state.expandingFolders[category].push(id);
      localStorage.setItem(LocalStorageConstant.EXPANDING_FOLDERS, JSON.stringify(state.expandingFolders));
    }
  },
  [REMOVE_OPEN_FOLDER]: (state, { id, category }) => {
    remove(id);
    function remove(id) {
      const index = state.expandingFolders[category].findIndex((i) => i == id);
      if (index == -1) return;
      state.expandingFolders[category].splice(index, 1);
      const childIds = state.childFolders[id] ? state.childFolders[id].map((i) => i.id) : [];
      childIds.forEach((childId) => remove(childId));
      localStorage.setItem(LocalStorageConstant.EXPANDING_FOLDERS, JSON.stringify(state.expandingFolders));
    }
  },
  [SET_DRAGGING_FOLDER]: (state, data) => {
    state.draggingFolderData = data;
  },
};
const actions = {
  loadFolders({ state, commit }, forceReload) {
    return new Promise((resolve, reject) => {
      if (!forceReload && state.stars.length > 0 && state.personals.length > 0 && state.shares.length > 0) {
        resolve({
          stars: state.stars,
          personals: state.personals,
          shares: state.shares,
        });
        /* eslint-disable-next-line */
        commit("SET_ROOT_FOLDER", data);
        return;
      }
      !forceReload &&
        util.getDataFromSw("nav-folders-0", (data) => {
          commit("SET_ROOT_FOLDER", data);
          resolve(data);
        });
      api
        .get_folders(0, !!forceReload)
        .then((res) => {
          commit("SET_ROOT_FOLDER", res);
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
  loadChildFolders(
    { commit, dispatch, state },
    { parentId, category, folder_types, copy_done, copy_folder_name, no_cache },
  ) {
    if (copy_done && pendingCopyFolders[parentId]) {
      pendingCopyFolders[parentId] = pendingCopyFolders[parentId].filter((f) => f.name !== copy_folder_name);
      if (pendingCopyFolders[parentId].length === 0) delete pendingCopyFolders[parentId];
    }
    if (parentId) {
      return new Promise((resolve, reject) => {
        commit("LOADING_CHILD_FOLDERS", true);
        util.getDataFromSw(`nav-folders-${parentId}`, (data) => {
          folder_types &&
            (data[parentId] = data[parentId].filter((f) => folder_types.findIndex((t) => t === f.folder_type) > -1));
          commit("SET_CHILD_FOLDERS", { parentId, folders: data, category });
          resolve(data);
        });
        api
          .get_folders(parentId, no_cache)
          .then((res) => {
            folder_types &&
              (res[parentId] = res[parentId].filter((f) => folder_types.findIndex((t) => t === f.folder_type) > -1));
            commit("SET_CHILD_FOLDERS", { parentId, folders: res, category });
            commit("LOADING_CHILD_FOLDERS", false);
            resolve(res);
          })
          .catch((error) => {
            console.log(error);
            commit("LOADING_CHILD_FOLDERS", false);
            reject(error);
          });
      });
    } else {
      return dispatch("loadFolders");
    }
  },
  loadChildFoldersAwait({ commit, dispatch, state }, { parentId, category }) {
    return new Promise(async (resolve, reject) => {
      const res = await api.get_folders(parentId, true);
      res && commit(SET_CHILD_FOLDERS, { parentId: parentId, folders: res, category });
      resolve(res);
    });
  },
  removeFolderChildren({ commit }, { folderId, category }) {
    const folders = FolderHelper.buildChildObject(folderId, []);
    commit(SET_CHILD_FOLDERS, { parentId: folderId, folders: folders, category });
    commit(UPDATE_FOLDER_CHILDREN, { folderId, category, folders: [] });
  },
  setFolders({ commit }, params) {
    commit("SET_ROOT_FOLDER", params);
  },
  create_folder({ dispatch }, { params, parent_structure }) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.create_folder(params);
        await dispatch("addFolderData", { folderData: res?.folder, parent_structure });
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
  async addFolderData({ commit, dispatch, state }, { folderData, parent_structure }) {
    const folder = { ...folderData, children: [] };
    const { parent_id, personal } = folder;
    const category = personal ? appConstant.folderCategory.PERSONAL : appConstant.folderCategory.SHARE;

    // In case added folder is under root folder
    if (parent_id == 0) {
      let { personals, shares, stars } = {
        ...state,
        personals: [...state.personals],
        shares: [...state.shares],
        stars: [...state.stars],
      };
      personal && !personals.find((f) => f.id === folder?.id) && personals.unshift(folder);
      !personal && !shares.find((f) => f.id === folder?.id) && shares.unshift(folder);
      sortFolders(parent_id, category, personal ? personals : shares);
      commit(SET_ROOT_FOLDER, { personals, shares, stars });
      return;
    }

    // In case added folder is under one has children.
    // Load children of target folder.
    let childrenLoaded = false;
    for (let parent of parent_structure) {
      childrenLoaded = state.childFolders[parent.id] && state.childFolders[parent.id].length > 0;
      parent.id &&
        !childrenLoaded &&
        (await dispatch("loadChildFoldersAwait", { parentId: parent.id, category: category }));
    }

    let childFolders = state.childFolders[parent_id] || [];
    if (childFolders.findIndex((f) => f.id == folder.id) == -1) {
      childFolders.unshift(folder);
      const folders = FolderHelper.buildChildObject(parent_id, childFolders);
      commit(SET_CHILD_FOLDERS, { parentId: parent_id, folders, category });
    }
    sortFolders(parent_id, category, childFolders);

    // Update parent info
    commit(UPDATE_FOLDER_INFO, { id: parent_id, folder: { has_children: true } });
    return;
  },
  update_folder({ commit, dispatch, state }, { params, parent_structure, originalFolder, indexInParent }) {
    return new Promise(async (resolve, reject) => {
      try {
        const { id, folder } = params;
        const res = await api.edit_folder(id, params);
        commit(UPDATE_FOLDER_INFO, { id, folder });

        const fromParentId = originalFolder.parent_id;
        const toParentId = folder.parent_id;
        const fromCategory = originalFolder.personal
          ? appConstant.folderCategory.PERSONAL
          : appConstant.folderCategory.SHARE;
        const toCategory = folder.personal ? appConstant.folderCategory.PERSONAL : appConstant.folderCategory.SHARE;

        // 1. In case dont change folder's location
        if (fromParentId == toParentId && fromCategory == toCategory) {
          resolve(res);
          return;
        }

        // 2. In case move folder
        // Load children of destination parent
        for (let parent of parent_structure) {
          const existChildren = state.childFolders[parent.id] && state.childFolders[parent.id].length > 0;
          parent.id &&
            !existChildren &&
            (await dispatch("loadChildFoldersAwait", { parentId: parent.id, category: toCategory }));
        }

        // Move folder
        let fromList =
          fromParentId == 0
            ? originalFolder.personal
              ? state.personals
              : state.shares
            : state.childFolders[fromParentId] || [];

        let toList =
          toParentId == 0 ? (folder.personal ? state.personals : state.shares) : state.childFolders[toParentId] || [];

        FolderHelper.moveFolder(folder, fromList, toList, indexInParent);
        fromParentId &&
          commit(SET_CHILD_FOLDERS, {
            parentId: fromParentId,
            folders: FolderHelper.buildChildObject(fromParentId, fromList),
            category: fromCategory,
          });
        toParentId &&
          commit(SET_CHILD_FOLDERS, {
            parentId: toParentId,
            folders: FolderHelper.buildChildObject(toParentId, toList),
            category: toCategory,
          });

        // Sort childrens of destination parent
        sortFolders(toParentId, toCategory, toList);

        // Update parent folder
        commit(UPDATE_FOLDER_INFO, { id: toParentId, folder: { has_children: true } });
        if (fromList && fromList.length == 0)
          commit(UPDATE_FOLDER_INFO, { id: fromParentId, folder: { has_children: false } });

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
  delete_folder({ dispatch }, { id, params, folder }) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await api.delete_Folder(id, params);
        dispatch("deleteFolderData", { folder });
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
  async deleteFolderData({ state, commit }, { folder }) {
    const category = folder.personal ? appConstant.folderCategory.PERSONAL : appConstant.folderCategory.SHARE;

    // Remove folder in personals/shares
    let list =
      folder.parent_id == 0
        ? folder.personal
          ? state.personals
          : state.shares
        : state.childFolders[folder.parent_id] || [];
    const index = list.findIndex((f) => f.id == folder.id);
    if (index !== -1) list.splice(index, 1);

    folder.parent_id &&
      commit(SET_CHILD_FOLDERS, {
        parentId: folder.parent_id,
        folders: FolderHelper.buildChildObject(folder.parent_id, list),
        category: category,
      });

    // Remove folder in star if existed.
    const indexInStars = state.stars.findIndex((f) => f.id == folder.id);
    indexInStars != -1 && state.stars.splice(indexInStars, 1);

    // Update parent folder in case children empty.
    if (list && list.length == 0) commit(UPDATE_FOLDER_INFO, { id: folder.parent_id, folder: { has_children: false } });

    return;
  },
  star_folder({ state, commit }, { params, isStar, folder }) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = isStar ? await api.star_folder(params) : await api.unStar_folder(params);
        const { id, parent_id, personal } = folder;
        let list = parent_id == 0 ? (personal ? state.personals : state.shares) : state.childFolders[parent_id] || [];

        let existedFolder = FolderHelper.findFolders(id, list)[0] || folder;
        if (existedFolder) {
          existedFolder.star = isStar;
          commit(UPDATE_FOLDER_INFO, { id, folder: existedFolder });
        }

        const starredFolder = { ...existedFolder, children: [] };
        const index = state.stars.length > 0 ? state.stars.findIndex((f) => f.id == id) : -1;
        isStar &&
          index == -1 &&
          state.stars.push(starredFolder) &&
          sortFolders(0, appConstant.folderCategory.star, state.stars);
        !isStar && index > -1 && state.stars.splice(index, 1);
        commit(SET_ROOT_FOLDER, state);

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
  hide_folder({ commit, state }, { folder, isHide, params }) {
    return new Promise(async (resolve, reject) => {
      try {
        const res = isHide ? await api.hidden_folder(params) : await api.show_folder(params);
        const { id } = folder;
        commit(UPDATE_FOLDER_INFO, { id, folder: { hidden: isHide } });
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  },
  prepend_child_folders({ commit, state }, { parentId, folders, category }) {
    if (parentId !== 0) {
      commit(PREPEND_CHILD_FOLDERS, { parentId, folders, category });
      commit(UPDATE_FOLDER_INFO, { id: parentId, folder: { has_children: true } });
      return;
    }

    commit(ADD_FOLDER_TO_ROOT, { category, folders });
  },
};

function sortFolders(parent_id, category, folders) {
  category != appConstant.folderCategory.SHARE &&
    folders.length > 1 &&
    api.post_folder_sort({
      parent_id: parent_id,
      children: folders.map((item) => item.id),
      folder_type: category == appConstant.folderCategory.PERSONAL ? "normal_folders" : "starred_folders",
    });
  category == appConstant.folderCategory.SHARE && FolderHelper.sortFolders(folders);
}

export default {
  state,
  getters,
  actions,
  mutations,
};
